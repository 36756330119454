import {
  Heading,
  Columns,
  Column,
  Box,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import type { ComponentProps } from 'react';

import { FEATURE_APR_NVL, useFeatureToggleData } from '../../featureToggle';

type BoxProps = ComponentProps<typeof Box>;

export interface SecondarySectionProps extends BoxProps {
  icon: ReactNodeNoStrings;
  direction?: 'row' | 'column';
  subTitleComponent?: ReactNodeNoStrings;
  subTitleText?: string;
  titleComponent?: ReactNodeNoStrings;
  titleText?: string;
  tailComponent?: ReactNodeNoStrings;
}

export const SecondarySection = ({
  children,
  icon,
  subTitleComponent,
  subTitleText,
  titleComponent,
  titleText,
  tailComponent,
  direction = 'row',
  ...otherProps
}: SecondarySectionProps) => {
  const isAPRNVLEnabled = useFeatureToggleData(FEATURE_APR_NVL, false);

  return (
    <Box
      data={{ id: 'primitive-secondary-section' }}
      style={direction === 'column' ? { height: '100%' } : {}}
      {...otherProps}
    >
      <Box
        background="surface"
        position="relative"
        borderRadius="standard"
        style={direction === 'column' ? { height: '100%' } : {}}
      >
        {direction === 'row' ? (
          <Columns
            alignY="center"
            space={isAPRNVLEnabled ? 'large' : 'gutter'}
            collapseBelow={isAPRNVLEnabled ? 'tablet' : undefined}
          >
            <Column width="content">
              <Box style={{ width: isAPRNVLEnabled ? '100%' : '48px' }}>
                {icon}
              </Box>
            </Column>
            <Column>
              <Box
                paddingTop={isAPRNVLEnabled ? 'none' : 'small'}
                style={{ maxWidth: isAPRNVLEnabled ? '100%' : '620px' }}
              >
                <Stack space={isAPRNVLEnabled ? 'small' : 'medium'}>
                  {titleText && <Heading level="3">{titleText}</Heading>}
                  {subTitleText && <Text>{subTitleText}</Text>}
                  {titleComponent}
                  {subTitleComponent}
                </Stack>
              </Box>
            </Column>
            {tailComponent ? (
              <Column width="content">{tailComponent}</Column>
            ) : null}
          </Columns>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            height="full"
            className="secondary-section"
            style={{ overflowY: 'auto' }}
          >
            <Box>{icon}</Box>
            <Box width="full" flexGrow={1}>
              <Stack space="medium">
                {titleText && <Heading level="3">{titleText}</Heading>}
                {subTitleText && <Text>{subTitleText}</Text>}
                {titleComponent}
                {subTitleComponent}
              </Stack>
            </Box>
            <Inline space="none">{tailComponent}</Inline>
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
};
