import type {
  ErrorLogger,
  GraphQLErrorLogger,
} from '@seek/hirer-graphql-client/lib-types/types';

import type { Logger } from '../../utils/logger/logger';

const mapRequestErrorLogger =
  (logger: Logger): ErrorLogger =>
  (thrownError, operationName, metadata) => {
    logger.error(`ApolloClient | Error | ${operationName}`, {
      originalError: thrownError,
      operationName,
      metadata,
    });
  };

const mapGraphQLErrorLogger =
  (logger: Logger): GraphQLErrorLogger =>
  (error, operationName, metadata) => {
    logger.error(`ApolloClient | GraphQLError | ${operationName}`, {
      originalError: error,
      operationName,
      metadata,
    });
  };

export { mapGraphQLErrorLogger, mapRequestErrorLogger };
