import { useTranslations } from '@vocab/react';
import { Stack, Text, Column, Columns, Link, Alert } from 'braid-design-system';

import type { SalaryType } from '../../types/AdPerformanceTypes';
import type { MyJobInformationResponse } from '../../types/MyJobInformationResponse';
import { formatDate } from '../../utils/date';
import {
  type FormatSalaryOptions,
  formatSalary,
} from '../../utils/formatters/salaryFormatter';
import { displayDefaultIfUnspecified } from '../../utils/formatters/stringFormatter';
import {
  AccordionSection,
  type AccordionSectionProps,
} from '../AccordionSection/AccordionSection';

import translations from './.vocab';

type StackProps = React.ComponentProps<typeof Stack>;

export interface MyJobInformationProps {
  accordionProps: Omit<AccordionSectionProps, 'children'>;
  details: MyJobInformationResponse;
}

export const MyJobInformation = ({
  accordionProps,
  details,
}: MyJobInformationProps) => {
  const {
    advertiserName,
    applyUrl,
    classificationName,
    createdByName,
    createdByEmail,
    isLinkOut,
    isPrivateAdvertiser,
    isSalaryHidden,
    jobCreatedTimestampUTC,
    jobLocationLabel,
    jobTitle,
    latestEdit,
    mirrorClassificationName,
    mirrorSubClassificationName,
    postTypeCategory,
    analyticsProductDisplayName,
    salary,
    subClassificationName,
    workType,
  } = details;

  const salaryMessage = salary?.additionalText;

  const { t } = useTranslations(translations);

  const getDisplayPayRange = (
    salaryType: SalaryType | null,
    salaryCurrency: string,
    minSalary: number,
    maxSalary: number,
  ): string => {
    const salaryOptions: FormatSalaryOptions = {
      currency: salaryCurrency,
      display: 'code',
    };

    const salaryRange = {
      minSalary: formatSalary(minSalary, salaryOptions),
      maxSalary: formatSalary(maxSalary, salaryOptions),
    };

    switch (salaryType) {
      case 'ANNUAL_COMMISSION':
      case 'COMMISSION_ONLY':
        return t(
          '{minSalary} to {maxSalary} annually plus commission',
          salaryRange,
        );
      case 'ANNUAL_PACKAGE':
        return t('{minSalary} to {maxSalary} annually', salaryRange);
      case 'MONTHLY':
        return t('{minSalary} to {maxSalary} monthly', salaryRange);
      case 'HOURLY':
        return t('{minSalary} to {maxSalary} hourly', salaryRange);
    }

    return t('{minSalary} to {maxSalary}', salaryRange);
  };

  const getDisplayWorkType = (): string => {
    switch (workType) {
      case 'CASUAL':
        return t('Casual/Vacation');
      case 'CONTRACT':
        return t('Contract/Temp');
      case 'FULL_TIME':
        return t('Full time');
      case 'PART_TIME':
        return t('Part time');
      default:
        return '';
    }
  };

  const textStackSpace: StackProps['space'] = 'small';

  return (
    <AccordionSection {...accordionProps}>
      <Stack space="xlarge">
        <Columns space="small">
          <Column>
            <Stack space="large">
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Company')}
                </Text>
                <Text>
                  {isPrivateAdvertiser
                    ? t('Private advertiser')
                    : advertiserName}
                </Text>
              </Stack>
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Role title')}
                </Text>
                <Text>{jobTitle}</Text>
              </Stack>
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Classification')}
                </Text>
                <Text>
                  {t('{classification} > {subClassification}', {
                    classification: classificationName,
                    subClassification: subClassificationName,
                  })}
                </Text>
              </Stack>
              {mirrorClassificationName && mirrorSubClassificationName ? (
                <Stack space={textStackSpace}>
                  <Text tone="secondary" size="small" weight="medium">
                    {t('Mirror classification')}
                  </Text>
                  <Text>
                    {t('{classification} > {subClassification}', {
                      classification: mirrorClassificationName,
                      subClassification: mirrorSubClassificationName,
                    })}
                  </Text>
                </Stack>
              ) : null}
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Location')}
                </Text>
                <Text>{jobLocationLabel}</Text>
              </Stack>
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Pay range')}
                </Text>
                <Text>
                  {getDisplayPayRange(
                    salary.type,
                    salary.currency,
                    salary.minimum,
                    salary.maximum,
                  )}
                </Text>
              </Stack>
              {isSalaryHidden ||
              salaryMessage === undefined ||
              salaryMessage === null ? (
                <Stack space={textStackSpace}>
                  <Text tone="secondary" size="small" weight="medium">
                    {t('Hidden salary')}
                  </Text>
                  <Text>
                    {t('You chose to hide salary details from candidates')}
                  </Text>
                </Stack>
              ) : (
                <Stack space={textStackSpace}>
                  <Text tone="secondary" size="small" weight="medium">
                    {t('Pay shown on ad')}
                  </Text>
                  <Text>{salaryMessage}</Text>
                </Stack>
              )}
              <Stack space="small">
                <Text tone="secondary" size="small" weight="medium">
                  {t('Work type')}
                </Text>
                <Text>{getDisplayWorkType()}</Text>
              </Stack>
            </Stack>
          </Column>
          <Column>
            <Stack space="large">
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Ad type')}
                </Text>
                <Text>{analyticsProductDisplayName}</Text>
              </Stack>
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Posted via')}
                </Text>
                <Text>
                  {postTypeCategory === 'DIRECT'
                    ? t('SEEK website')
                    : t('External system')}
                </Text>
              </Stack>
              {isLinkOut && applyUrl ? (
                <Stack space={textStackSpace}>
                  <Text tone="secondary" size="small" weight="medium">
                    {t('Apply button')}
                  </Text>
                  <Link href={applyUrl}>
                    <Text>{t('Link To external website')}</Text>
                  </Link>
                </Stack>
              ) : null}
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Posted on')}
                </Text>
                <Text>
                  {formatDate({
                    date: new Date(jobCreatedTimestampUTC),
                    dateFormat: 'd MMM yyyy',
                  })}
                </Text>
              </Stack>
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Created by')}
                </Text>
                <Text>
                  {displayDefaultIfUnspecified(
                    createdByName,
                    t('Unspecified user'),
                  )}
                </Text>
              </Stack>
              <Stack space={textStackSpace}>
                <Text tone="secondary" size="small" weight="medium">
                  {t('Email contact')}
                </Text>
                <Text>
                  {displayDefaultIfUnspecified(
                    createdByEmail,
                    t('Unspecified user'),
                  )}
                </Text>
              </Stack>
            </Stack>
          </Column>
        </Columns>
        <Stack space="large">
          {latestEdit && (
            <Alert tone="info">
              <Text>
                {latestEdit.name
                  ? t('This ad was last edited on {date} by {name}.', {
                      name: latestEdit.name,
                      date: formatDate({
                        date: new Date(latestEdit.timestampUTC),
                        dateFormat: 'd MMM yyyy',
                      }),
                    })
                  : t('This ad was last edited on {date}.', {
                      date: formatDate({
                        date: new Date(latestEdit.timestampUTC),
                        dateFormat: 'd MMM yyyy',
                      }),
                    })}
              </Text>
            </Alert>
          )}
        </Stack>
      </Stack>
    </AccordionSection>
  );
};
