import { type Country, getSiteFromZoneAndProduct } from '@seek/melways-sites';
import { Box, Inline, Loader, Stack } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { useConfig } from '../../App/ConfigContext';
import { AdRatingSection } from '../../blocks/AdRatingSection/AdRatingSection';
import { AdRatingSectionFallback } from '../../blocks/AdRatingSection/AdRatingSectionFallback';
import { MoreStatsFallback } from '../../blocks/MoreStats/MoreStatsFallback';
import { MoreStatsNvl } from '../../blocks/MoreStats/MoreStatsNvl';
import { AdPerformancePageFrameNvl } from '../../components/AdPerformancePageFrame/AdPerformancePageFrameNvl';
import { AdRatingNotice } from '../../components/AdRatingNotice/AdRatingNotice';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { NoAccessNvl } from '../../components/NoAccess/NoAccessNvl';
import { RoundedCard } from '../../components/RoundedCard/RoundedCard';
import { getLocale, isLocal } from '../../config';
import { HasAdRatingProvider } from '../../context/hasAdRating';
import { HasMarketInsightsProvider } from '../../context/hasMarketInsights';
import { useFeatureToggleData, FEATURE_BENCHMARKS } from '../../featureToggle';
import { useAdPerformanceReport } from '../../hooks/useAdPerformanceReport';
import { useAuth } from '../../hooks/useAuth';
import { useRefFromUrl } from '../../hooks/useRefFromUrl';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { triggerHotjar } from '../../utils/hotjar';
import {
  getBrandFromSiteName,
  trackAPRPage,
} from '../../utils/tealiumEventTracker';
import { MarketInsights } from '../../widgets/MarketInsights/MarketInsights';
import { MarketInsightsFallback } from '../../widgets/MarketInsights/MarketInsightsFallback';
import { MyJobInformation } from '../../widgets/MyJobInformation/MyJobInformation';
import { MyJobInformationFallback } from '../../widgets/MyJobInformation/MyJobInformationFallback';
import { PageTitleStickySection } from '../../widgets/PageTitleStickySection/PageTitleStickySection';
import { Recommendations } from '../../widgets/Recommendations/Recommendations';
import { RecommendationsFallback } from '../../widgets/Recommendations/RecommendationsFallback';
import { SimilarAds } from '../../widgets/SimilarAds/SimilarAds';
import { SimilarAdsFallback } from '../../widgets/SimilarAds/SimilarAdsFallback';

import { AdPerformanceReportFallbackNvl } from './AdPerformanceReportFallbackNvl';

export interface AdPerformanceReportProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
}

export const AdPerformanceReportNvl = ({
  adCentreAccountInfo,
}: AdPerformanceReportProps) => {
  /* istanbul ignore next */
  if (!isLocal()) {
    if (window.location.hostname.includes('.staging')) {
      triggerHotjar('poll_iap');
    } else {
      triggerHotjar('poll_iap_prod');
    }
  }

  const { language, zone } = useConfig();
  const { userIdentity } = useAuth();
  const { jobId: jobIdParam } = useParams<{
    jobId: string;
  }>();
  const {
    data: adPerformanceReportData,
    error,
    isLoading,
  } = useAdPerformanceReport({ jobId: jobIdParam });
  const userId = adCentreAccountInfo?.headerFooterContext?.user?.id;
  const isBenchmarksFeatureEnabled = useFeatureToggleData(
    FEATURE_BENCHMARKS,
    false,
  );
  const refFromUrl = useRefFromUrl();

  if (isLoading && !adPerformanceReportData) {
    return (
      <AdPerformancePageFrameNvl adCentreAccountInfo={adCentreAccountInfo}>
        <Stack space="large">
          <RoundedCard>
            <Inline align="center" space="none">
              <Box padding="large">
                <Loader />
              </Box>
            </Inline>
          </RoundedCard>
        </Stack>
      </AdPerformancePageFrameNvl>
    );
  }

  if (error || !adPerformanceReportData) {
    // TODO: to implement new datadog logger to log error
    const isUnauthorisedError =
      error?.graphQLErrors?.[0]?.extensions?.code === 'UNAUTHENTICATED' ||
      error?.graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN';

    if (isUnauthorisedError) {
      return (
        <AdPerformanceReportFallbackNvl
          adCentreAccountInfo={adCentreAccountInfo}
        >
          <NoAccessNvl />
        </AdPerformanceReportFallbackNvl>
      );
    }

    return (
      <AdPerformanceReportFallbackNvl
        adCentreAccountInfo={adCentreAccountInfo}
      />
    );
  }

  const { hirerId } = adPerformanceReportData;
  const siteCountry = getLocale().split('-')[1].toLowerCase() as Country;
  const siteName = getSiteFromZoneAndProduct(zone, 'employer');
  const brand = getBrandFromSiteName(siteName);

  trackAPRPage({
    siteCountry,
    currentPage: 'ad performance report',
    hirerUserEmail: userIdentity?.email || null,
    hirerUserFullName:
      adCentreAccountInfo?.headerFooterContext?.user?.name || null,
    seekAdvertiserId: hirerId,
    jobId: parseInt(jobIdParam as string, 10),
    userId: (userId && parseInt(userId, 10)) || null,
    zone,
    siteLanguage: language,
    adPerformance: null,
    adPostingType: null,
    adDaysLive: null,
    adTipsCount: null,
    adStatus: null,
    siteSection: 'jobs',
    siteSubsection: 'analytics',
    adTips: null,
    brand,
    actionOrigin: refFromUrl,
  });

  return (
    <AdPerformancePageFrameNvl
      adCentreAccountInfo={adCentreAccountInfo}
      hirerId={hirerId}
      stickyBar={
        <ErrorBoundary fallback={null}>
          <PageTitleStickySection />
        </ErrorBoundary>
      }
    >
      <Box>
        <Stack space="xlarge">
          <ErrorBoundary fallback={<AdRatingSectionFallback />}>
            <AdRatingSection />
          </ErrorBoundary>
          <Stack space="medium">
            <ErrorBoundary fallback={<MoreStatsFallback />}>
              <HasAdRatingProvider>
                <MoreStatsNvl />
              </HasAdRatingProvider>
            </ErrorBoundary>
            <AdRatingNotice />
          </Stack>
          <ErrorBoundary fallback={<RecommendationsFallback />}>
            <Recommendations />
          </ErrorBoundary>
          {isBenchmarksFeatureEnabled && (
            <ErrorBoundary fallback={<SimilarAdsFallback />}>
              <SimilarAds />
            </ErrorBoundary>
          )}
          {isBenchmarksFeatureEnabled && (
            <ErrorBoundary fallback={<MarketInsightsFallback />}>
              <HasMarketInsightsProvider>
                <MarketInsights />
              </HasMarketInsightsProvider>
            </ErrorBoundary>
          )}
          <ErrorBoundary fallback={<MyJobInformationFallback />}>
            <MyJobInformation />
          </ErrorBoundary>
        </Stack>
        <Box id="modal-base">&nbsp;</Box>
      </Box>
    </AdPerformancePageFrameNvl>
  );
};
