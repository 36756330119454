import { useTranslations } from '@vocab/react';
import {
  ButtonIcon,
  Dialog,
  IconInfo,
  Text,
  TextLink,
} from 'braid-design-system';
import { type ReactNode, useState } from 'react';
import { HashLink } from 'react-router-hash-link';

import translations from './.vocab';

import * as styles from './ClickableTooltip.css';

export interface ClickableTooltipProps {
  id: string;
  title: string;
  contents: ReactNode;
  size?: React.ComponentProps<typeof ButtonIcon>['size'];
  hashLinkProps?: React.ComponentProps<typeof HashLink>;
  hashLinkLabel?: string;
}

export const ClickableTooltip = ({
  id,
  title,
  contents,
  size = 'standard',
  hashLinkProps,
  hashLinkLabel,
}: ClickableTooltipProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslations(translations);

  const handleHashlinkClick = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonIcon
        id={`tooltip-trigger-${id}`}
        data={{ testid: `tooltip-trigger-${id}` }}
        label={t('Click to learn more')}
        icon={<IconInfo />}
        size={size}
        variant="transparent"
        bleed={true}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog
        id={`tooltip-dialog-${id}`}
        title={title}
        open={open}
        closeLabel={t('Close')}
        onClose={() => {
          setOpen(false);
        }}
      >
        {contents}
        {hashLinkProps && (
          <Text>
            <TextLink href="">
              <HashLink
                className={styles.hashLink}
                {...hashLinkProps}
                onClick={handleHashlinkClick}
              >
                {hashLinkLabel}
              </HashLink>
            </TextLink>
          </Text>
        )}
      </Dialog>
    </>
  );
};
