import { useTranslations } from '@vocab/react';
import { Stack, Text } from 'braid-design-system';
import type React from 'react';

import { NoRecommendationIcon } from '../../assets/icons/NoRecommendationIcon/NoRecommendationIcon';

import localTranslations from './.vocab';

export const NoRecommendation = () => {
  const { t } = useTranslations(localTranslations);

  return (
    <Stack space="medium" align="center">
      <Text size="large" tone="secondary" weight="medium">
        <NoRecommendationIcon />
        {t("We don't have any suggestions to improve this ad.")}
      </Text>
    </Stack>
  );
};
