import { Bleed, Box, ContentBlock } from 'braid-design-system';

import { AdUsagePageFrame } from '../../components/AdUsagePageFrame/AdUsagePageFrame';
import { PageLoadErrorNvl } from '../../components/PageLoadError/PageLoadErrorNvl';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
export interface AdUsageReportFallbackProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  children?: ReactNodeNoStrings;
}

export const AdUsageReportFallbackNvl = ({
  adCentreAccountInfo,
  children = <PageLoadErrorNvl />,
}: AdUsageReportFallbackProps) => (
  <AdUsagePageFrame adCentreAccountInfo={adCentreAccountInfo}>
    <Box paddingTop="gutter" paddingBottom="large">
      <ContentBlock width="large">
        <Bleed horizontal="large">{children}</Bleed>
      </ContentBlock>
    </Box>
  </AdUsagePageFrame>
);
