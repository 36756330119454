import { useTranslations } from '@vocab/react';
import { Stack, Text } from 'braid-design-system';

import { JigsawIcon } from '../../assets/icons/JigsawIcon/JigsawIcon';
import { PageError } from '../PageError/PageError';

import translations from './.vocab';

export const PageLoadError = () => {
  const { t } = useTranslations(translations);

  return (
    <PageError
      icon={<JigsawIcon width="115" height="101" />}
      title={t("We can't load this report right now")}
      description={t(
        "We're working on getting this back up. Refresh the page or check back later.",
        {
          Stack: (children: ReactNodeNoStrings) => (
            <Stack space="small">{children}</Stack>
          ),
          Text: (children) => (
            <Text tone="secondary" align="center">
              {children}
            </Text>
          ),
        },
      )}
    />
  );
};
