import { useTranslations } from '@vocab/react';
import { Stack, Text, Box } from 'braid-design-system';
import type React from 'react';

import { FEATURE_APR_NVL, useFeatureToggleData } from '../../featureToggle';
import { Medium } from '../Medium/Medium';

import translations from './.vocab';

export const AppStartsTooltipContent = () => {
  const { t } = useTranslations(translations);
  const isAPRNVLEnabled = useFeatureToggleData(FEATURE_APR_NVL, false);

  return (
    <Box data={{ id: 'ui-app-starts-tooltip-content' }}>
      <Stack space="medium">
        <Stack space="small">
          <Text weight="strong" size={isAPRNVLEnabled ? 'standard' : 'small'}>
            {t("What's this number")}
          </Text>
          <Text size={isAPRNVLEnabled ? 'standard' : 'small'}>
            {t('Number reflects apps started', {
              Status: (children) => <Medium>{children}</Medium>,
            })}
          </Text>
        </Stack>
        <Text size={isAPRNVLEnabled ? 'standard' : 'small'}>
          {t('Apps complete count not possible for indirect jobs')}
        </Text>
      </Stack>
    </Box>
  );
};
