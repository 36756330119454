import { FEATURE_APR_NVL, useFeatureToggleData } from '../../featureToggle';
import { RoundedCard, type RoundedCardProps } from '../RoundedCard/RoundedCard';

export const PrimarySection = ({
  children,
  ...otherProps
}: RoundedCardProps) => {
  const isAPRNVLEnabled = useFeatureToggleData(FEATURE_APR_NVL, false);

  return (
    <RoundedCard
      data-id="primitive-primary-section"
      padding={isAPRNVLEnabled ? 'none' : 'large'}
      {...otherProps}
    >
      {children}
    </RoundedCard>
  );
};
