import { useIsNVLEnabled } from '@seek/uiv-banner';
import { useTranslations } from '@vocab/react';
import { Card, Box, Alert, Text, BraidProvider } from 'braid-design-system';
import apac from 'braid-design-system/themes/apac';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { useState, useEffect, type ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import {
  type Location,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';

import { useAdCentreAccountInfo } from '../../src/hooks/useAdCentreAccountInfo';
import translations from '../.vocab';
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { UivBanner } from '../components/UivBanner';
import { FEATURE_APR_NVL, useFeatureToggleData } from '../featureToggle';
import { AdPerformanceReport } from '../pages/AdPerformanceReport/AdPerformanceReport';
import { AdPerformanceReportFallback } from '../pages/AdPerformanceReport/AdPerformanceReportFallback';
import { AdPerformanceReportFallbackNvl } from '../pages/AdPerformanceReport/AdPerformanceReportFallbackNvl';
import { AdPerformanceReportNvl } from '../pages/AdPerformanceReport/AdPerformanceReportNvl';
import { AdUsageReport } from '../pages/AdUsageReport/AdUsageReport';
import { AdUsageReportFallback } from '../pages/AdUsageReport/AdUsageReportFallback';
import LoggerProvider from '../utils/logger/logger';

import { AppLoader } from './AppLoader';
import { useConfig } from './ConfigContext';
import { localizeRoutesForSite } from './RouterConfig';
import { useAuth } from './hooks/useAuth';

export const Router = () => {
  const { t } = useTranslations(translations);
  const { urlResolver, absoluteUrlResolver, site } = useConfig();
  const [error, setError] = useState<string | null>(null);
  const {
    error: authenticationError,
    login,
    isLoading: isLoadingAuth,
    isSeekStaff,
    token,
  } = useAuth();
  const currLocation = useLocation();

  const currAdvertiserId = window.localStorage.getItem(
    'adv-account-selection-seek-id',
  );

  const {
    data: adCentreAccountInfo,
    error: adCentreAccountInfoError,
    isLoading: isLoadingAccountInfo,
  } = useAdCentreAccountInfo(token);

  const isNVLEnabled = useIsNVLEnabled(false);

  const siteRoutes = localizeRoutesForSite(site);

  const isAPRNVLEnabled = useFeatureToggleData(FEATURE_APR_NVL, false);

  useEffect(() => {
    if (Boolean(authenticationError)) {
      setError(t('Error Authenticating'));
    } else {
      setError(null);
    }
  }, [authenticationError, t]);

  useEffect(() => {
    if (isLoadingAuth) {
      return;
    }

    const userNeedsLogin =
      token === null ||
      (adCentreAccountInfoError &&
        !isSeekStaff &&
        [
          'No selected advertiser ID',
          'Invalid selected advertiser ID',
        ].includes(adCentreAccountInfoError.message));
    if (userNeedsLogin) {
      login();
    }
  }, [adCentreAccountInfoError, login, isLoadingAuth, isSeekStaff, token]);

  useEffect(() => {
    const mapRouteToExperience = (location: Location) => {
      if (
        location.pathname.includes('/ad-usage') ||
        location.pathname.includes('/ad-performance')
      ) {
        return 'new';
      }

      return 'old';
    };

    LoggerProvider.logger.configure({
      userId: adCentreAccountInfo?.headerFooterContext?.user?.id,
      advertiserId: currAdvertiserId || '',
      experience: mapRouteToExperience(currLocation),
    });
  }, [adCentreAccountInfo, currAdvertiserId, currLocation]);

  if (
    window.location.href.includes('/#/myyearwithseek') ||
    window.location.href.includes('/myyearwithseek') ||
    window.location.href.includes('/myseekreview')
  ) {
    window.location.assign(
      'https://ui.backoffice.prod.myseek.xyz/analytics-reports',
    );
    return null;
  }

  const NavigateToAdUsage = () => (
    <Navigate replace to={urlResolver('/analytics/ad-usage')} />
  );

  const NavigateToAdPerformance = () => {
    const { jobId } = useParams();
    return (
      <Navigate
        replace
        to={urlResolver(`/analytics/ad-performance/${jobId}`)}
      />
    );
  };

  const NavigateToDefault = () => {
    const shouldRedirectToRoleInsights =
      adCentreAccountInfo?.headerFooterContext.advertiser?.permissionOptions
        .canViewInsightsRoleReport &&
      !adCentreAccountInfo.headerFooterContext.advertiser.permissionOptions
        .canViewManagerReports;

    // Redirect to role insights if user only has permission to view role insights
    if (shouldRedirectToRoleInsights) {
      window.location.assign(absoluteUrlResolver('/analytics/role-insights'));
      return null;
    }

    return <NavigateToAdUsage />;
  };

  const AdUsagePage = (
    <>
      <BraidProvider theme={isNVLEnabled ? seekJobs : apac}>
        <Helmet>
          <title>{t('Ad usage - Analytics | SEEK Employer')}</title>
        </Helmet>
        <ErrorBoundary
          fallback={
            <AdUsageReportFallback adCentreAccountInfo={adCentreAccountInfo} />
          }
        >
          <AdUsageReport adCentreAccountInfo={adCentreAccountInfo} />
        </ErrorBoundary>
      </BraidProvider>
    </>
  );
  const AdPerformancePage = (
    <>
      <BraidProvider theme={isNVLEnabled ? seekJobs : apac}>
        <Helmet>
          <title>{t('Ad performance - Analytics | SEEK Employer')}</title>
        </Helmet>
        {isAPRNVLEnabled ? (
          <ErrorBoundary
            fallback={
              <AdPerformanceReportFallbackNvl
                adCentreAccountInfo={adCentreAccountInfo}
              />
            }
          >
            <AdPerformanceReportNvl adCentreAccountInfo={adCentreAccountInfo} />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary
            fallback={
              <AdPerformanceReportFallback
                adCentreAccountInfo={adCentreAccountInfo}
              />
            }
          >
            <AdPerformanceReport adCentreAccountInfo={adCentreAccountInfo} />
          </ErrorBoundary>
        )}
      </BraidProvider>
    </>
  );
  const routeNameToPage: Record<string, ReactNode> = {
    '/analytics/ad-usage': !isLoadingAccountInfo ? AdUsagePage : null,
    '/analytics/ad-performance/$jobId': !isLoadingAccountInfo
      ? AdPerformancePage
      : null,
    '/analytics': <NavigateToDefault />,
    '/analytics/advertiser/$advertiserId': <NavigateToAdUsage />,
    '/analytics/job/$jobId': <NavigateToAdPerformance />,
  };

  return isLoadingAccountInfo ? (
    <AppLoader />
  ) : (
    <div>
      <UivBanner />
      {error && (
        <Box>
          <Card>
            <Alert tone="critical">
              <Text>{`${error}`}</Text>
            </Alert>
          </Card>
        </Box>
      )}
      <Routes>
        {siteRoutes.map(({ name, route }) => (
          <Route
            path={route.replace('$', ':')}
            key={route}
            element={routeNameToPage[name]}
          />
        ))}
      </Routes>
    </div>
  );
};
