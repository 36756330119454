import type {
  ApolloCache,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import graphQLClient from '@seek/hirer-graphql-client/';
import type {
  Environment,
  TokenGetter,
} from '@seek/hirer-graphql-client/lib-types/types';

import {
  getDevToken,
  isLocal,
  getSite,
  getDevGraphUri,
  getVersion,
} from '../../config';
import LoggerProvider from '../../utils/logger/logger';

import {
  mapGraphQLErrorLogger,
  mapRequestErrorLogger,
} from './hirerApi.loggers';

const getHeaders = () => ({
  // Add the version of the application to track in splunk and datadog over time
  'seek-request-site-version': getVersion(),
});

export function getLocalStackHeaders() {
  const email = 'seekUsername@seek.com.au';
  const token = getDevToken();
  const advertiserId = '28892855';

  window.localStorage.setItem('adv-account-selection-seek-id', advertiserId);

  return {
    'User-Id': email,
    'X-Seek-Oidc-Identity': JSON.stringify({
      sub: {
        ID: email,
        email,
        email_verified: true,
        country: 'AU',
        brand: 'seek',
        experience: 'hirer',
      },
    }),
    advertiserId,
    Authorization: `Bearer ${token}`,
  };
}

export const createHirerApiClient = ({
  cache,
  getAuthToken,
  renewAuthToken,
  login,
  environment,
}: {
  cache: ApolloCache<NormalizedCacheObject>;
  getAuthToken: TokenGetter;
  renewAuthToken: TokenGetter;
  login: () => void;
  environment: Environment;
}): ApolloClient<NormalizedCacheObject> => {
  const logger = LoggerProvider.logger;
  const site = getSite();
  const appName = 'hirer-ad-usage-ui';
  if (isLocal()) {
    return graphQLClient({
      cache,
      environment: 'development',
      getHeaders: () => ({
        ...getLocalStackHeaders(),
        ...getHeaders(),
      }),
      getAuthToken: async () => getDevToken() || '',
      renewAuthToken: async () => getDevToken() || '',
      login,
      logMetrics: () => {},
      logGraphQLError: mapGraphQLErrorLogger(logger),
      logError: mapRequestErrorLogger(logger),
      maxRetry: 3,
      site: 'employer-seek-au',
      appName,
      uri: getDevGraphUri(),
    });
  }
  return graphQLClient({
    cache,
    environment,
    appName,
    site,
    getAuthToken,
    renewAuthToken,
    login,
    logMetrics: () => {},
    logGraphQLError: mapGraphQLErrorLogger(logger),
    logError: mapRequestErrorLogger(logger),
    getHeaders,
  });
};
