import { useResponsiveValue } from 'braid-design-system';

export const useMobileView = () => {
  const responsiveValue = useResponsiveValue();

  const isMobile = responsiveValue({
    mobile: true,
    tablet: false,
    desktop: false,
  });

  return isMobile;
};
