export const mockHeaderFooterContext = {
  headerFooterContext: {
    identityContext: {
      act: null,
      email: 'Lord@seek.com',
    },
    advertiser: {
      billingId: '1234567',
      hasMultipleAccounts: false,
      isActivationPending: false,
      isAgency: false,
      name: 'SEEK Test Account',
      showOnCreditHoldMessage: false,
      switchAccountsHref: 'switchAccountsHref' || null,
      permissionOptions: {
        canCreateJobs: false,
        canManageBrand: false,
        canManageCompanyProfile: false,
        canManageUsers: false,
        canPayInvoices: false,
        canViewInvoiceHistory: false,
        canViewManagerReports: true,
        canViewInsightsRoleReport: false,
        canClaimCompanyProfile: false,
      },
    },
    user: {
      name: 'Lord Mockington',
      firstName: 'Lord',
      id: '123456',
    },
  },
  hirerOriginZone: 'anz-1',
};
