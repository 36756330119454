export const NoRecommendationIcon = () => (
  <svg width="223px" height="133px" viewBox="0 0 223 133">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon">
        <g id="Group-3" transform="translate(116.000000, 117.000000)">
          <mask id="mask-2" fill="white">
            <path
              d="M0.098,0.889 L0.098,3.226 C0.098,5.347 1.414,7.152 3.275,7.889 L3.275,7.889 C3.846,8.116 4.466,8.25 5.12,8.25 L5.12,8.25 L73.198,8.25 C73.852,8.25 74.473,8.116 75.043,7.889 L75.043,7.889 C76.904,7.152 78.221,5.347 78.221,3.226 L78.221,3.226 L78.221,0.889 L0.098,0.889 Z"
              id="path-1"
            />
          </mask>
          <polygon
            id="Fill-1"
            fill="#EFF3FA"
            mask="url(#mask-2)"
            points="-4.903 13.25 83.222 13.25 83.222 -4.111 -4.903 -4.111"
          />
        </g>
        <g id="Group-6" transform="translate(38.000000, 118.000000)">
          <mask id="mask-4" fill="white">
            <path
              d="M0.565,0.389 L0.565,7.389 L103.565,7.389 C103.565,3.523 100.431,0.389 96.566,0.389 L96.566,0.389 L0.565,0.389 Z"
              id="path-3"
            />
          </mask>
          <polygon
            id="Fill-4"
            fill="#EFF3FA"
            mask="url(#mask-4)"
            points="-4.435 12.389 108.565 12.389 108.565 -4.611 -4.435 -4.611"
          />
        </g>
        <path
          d="M121.1201,28.0277 C118.3461,28.0277 116.0971,30.2777 116.0971,33.0517 L116.0971,120.2257 C116.0971,123.0007 118.3461,125.2507 121.1201,125.2507 L189.1981,125.2507 C191.9731,125.2507 194.2221,123.0007 194.2221,120.2257 L194.2221,33.0517 C194.2221,30.2777 191.9731,28.0277 189.1981,28.0277 L159.6991,28.0277"
          id="Stroke-7"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="183.915"
          y1="101.6722"
          x2="169.565"
          y2="101.6722"
          id="Stroke-9"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="183.915"
          y1="107.6722"
          x2="159.565"
          y2="107.6722"
          id="Stroke-10"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M101.542,28.0277 C104.316,28.0277 106.565,30.2777 106.565,33.0517 L106.565,120.2257 C106.565,123.0007 104.316,125.2507 101.542,125.2507 L33.464,125.2507 C30.689,125.2507 28.44,123.0007 28.44,120.2257 L28.44,33.0517 C28.44,30.2777 30.689,28.0277 33.464,28.0277 L59.565,28.0277"
          id="Stroke-11"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="48.5654"
          y1="100.5902"
          x2="59.5654"
          y2="100.5902"
          id="Stroke-13"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="47.5654"
          y1="106.3021"
          x2="59.5654"
          y2="106.3021"
          id="Stroke-14"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <g id="Group-17" transform="translate(38.000000, 41.000000)">
          <mask id="mask-6" fill="white">
            <path
              d="M0.746,15.483 C0.746,23.864 7.541,30.66 15.923,30.66 L15.923,30.66 C24.305,30.66 31.1,23.864 31.1,15.483 L31.1,15.483 C31.1,7.102 24.305,0.307 15.923,0.307 L15.923,0.307 C7.541,0.307 0.746,7.102 0.746,15.483 L0.746,15.483 Z"
              id="path-5"
            />
          </mask>
          <polygon
            id="Fill-15"
            fill="#FFFFFF"
            mask="url(#mask-6)"
            points="-4.253 35.66 36.1 35.66 36.1 -4.693 -4.253 -4.693"
          />
        </g>
        <path
          d="M59.6592,70.5384 C57.8892,71.2614 55.9532,71.6594 53.9232,71.6594 C45.5422,71.6594 38.7472,64.8644 38.7472,56.4824 C38.7472,48.1014 45.5422,41.3074 53.9232,41.3074 C55.9612,41.3074 57.9052,41.7094 59.6802,42.4374"
          id="Stroke-18"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M159.8857,42.2552 L183.3917,42.2552 L183.3917,61.5972 C183.3917,66.1072 179.7367,69.7622 175.2277,69.7622 L159.8857,69.7622"
          id="Stroke-20"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <g id="Group-25" transform="translate(169.000000, 79.000000)">
          <mask id="mask-8" fill="white">
            <path
              d="M4.365,0.389 C2.266,0.389 0.565,2.09 0.565,4.187 L0.565,4.187 L0.565,10.59 C0.565,12.688 2.266,14.389 4.365,14.389 L4.365,14.389 L10.767,14.389 C12.864,14.389 14.565,12.688 14.565,10.59 L14.565,10.59 L14.565,4.187 C14.565,2.09 12.864,0.389 10.767,0.389 L10.767,0.389 L4.365,0.389 Z"
              id="path-7"
            />
          </mask>
          <polygon
            id="Fill-23"
            fill="#EFF3FA"
            mask="url(#mask-8)"
            points="-4.435 19.389 19.565 19.389 19.565 -4.611 -4.435 -4.611"
          />
        </g>
        <g id="Group-28" transform="translate(151.000000, 79.000000)">
          <mask id="mask-10" fill="white">
            <path
              d="M3.99,0.389 C1.892,0.389 0.191,2.09 0.191,4.187 L0.191,4.187 L0.191,10.59 C0.191,12.688 1.892,14.389 3.99,14.389 L3.99,14.389 L10.391,14.389 C12.49,14.389 14.19,12.688 14.19,10.59 L14.19,10.59 L14.19,4.187 C14.19,2.09 12.49,0.389 10.391,0.389 L10.391,0.389 L3.99,0.389 Z"
              id="path-9"
            />
          </mask>
          <polygon
            id="Fill-26"
            fill="#EFF3FA"
            mask="url(#mask-10)"
            points="-4.81 19.389 19.19 19.389 19.19 -4.611 -4.81 -4.611"
          />
        </g>
        <g id="Group-31" transform="translate(64.000000, 20.000000)">
          <mask id="mask-12" fill="white">
            <path
              d="M5.855,0.389 C3.079,0.389 0.83,2.638 0.83,5.412 L0.83,5.412 L0.83,107.366 C0.83,110.14 3.079,112.389 5.855,112.389 L5.855,112.389 L85.807,112.389 C88.582,112.389 90.83,110.14 90.83,107.366 L90.83,107.366 L90.83,5.412 C90.83,2.638 88.582,0.389 85.807,0.389 L85.807,0.389 L5.855,0.389 Z"
              id="path-11"
            />
          </mask>
          <polygon
            id="Fill-29"
            fill="#FFFFFF"
            mask="url(#mask-12)"
            points="-4.169 117.389 95.831 117.389 95.831 -4.611 -4.169 -4.611"
          />
        </g>
        <path
          d="M149.8076,132.389 L69.8546,132.389 C67.0796,132.389 64.8306,130.14 64.8306,127.366 L64.8306,25.412 C64.8306,22.638 67.0796,20.389 69.8546,20.389 L149.8076,20.389 C152.5816,20.389 154.8306,22.638 154.8306,25.412 L154.8306,127.366 C154.8306,130.14 152.5816,132.389 149.8076,132.389 Z"
          id="Stroke-32"
          stroke="#BBC8DC"
          strokeWidth="2"
        />
        <line
          x1="88.5654"
          y1="92.889"
          x2="130.5654"
          y2="92.889"
          id="Stroke-34"
          stroke="#BBC8DC"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="85.793"
          y1="107.889"
          x2="97.565"
          y2="107.889"
          id="Stroke-35"
          stroke="#BBC8DC"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="103.793"
          y1="107.889"
          x2="115.565"
          y2="107.889"
          id="Stroke-36"
          stroke="#BBC8DC"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="121.793"
          y1="107.889"
          x2="133.565"
          y2="107.889"
          id="Stroke-37"
          stroke="#BBC8DC"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="94.793"
          y1="113.889"
          x2="106.565"
          y2="113.889"
          id="Stroke-38"
          stroke="#BBC8DC"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="112.793"
          y1="113.889"
          x2="124.565"
          y2="113.889"
          id="Stroke-39"
          stroke="#BBC8DC"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <g id="Group-42" transform="translate(87.000000, 36.000000)">
          <mask id="mask-14" fill="white">
            <path
              d="M0.331,22.889 C0.331,35.316 10.404,45.389 22.831,45.389 L22.831,45.389 C35.258,45.389 45.331,35.316 45.331,22.889 L45.331,22.889 C45.331,10.462 35.258,0.389 22.831,0.389 L22.831,0.389 C10.404,0.389 0.331,10.462 0.331,22.889 L0.331,22.889 Z"
              id="path-13"
            />
          </mask>
          <polygon
            id="Fill-40"
            fill="#FFFFFF"
            mask="url(#mask-14)"
            points="-4.669 50.389 50.331 50.389 50.331 -4.611 -4.669 -4.611"
          />
        </g>
        <g id="Group-47" transform="translate(26.000000, 66.000000)">
          <mask id="mask-16" fill="white">
            <path
              d="M6.229,1.855 L0.964,27.316 C0.797,28.127 1.318,28.921 2.13,29.088 L2.13,29.088 C2.941,29.257 3.734,28.734 3.902,27.924 L3.902,27.924 L9.167,2.463 C9.334,1.651 8.812,0.857 8.001,0.69 L8.001,0.69 C7.898,0.669 7.796,0.659 7.694,0.659 L7.694,0.659 C7,0.659 6.375,1.146 6.229,1.855 L6.229,1.855 Z"
              id="path-15"
            />
          </mask>
          <polygon
            id="Fill-45"
            fill="#EFF3FA"
            mask="url(#mask-16)"
            points="-4.067 34.12 14.198 34.12 14.198 -4.341 -4.067 -4.341"
          />
        </g>
        <path
          d="M28.1299,95.0882 L28.1299,95.0882 C27.3179,94.9212 26.7969,94.1272 26.9639,93.3162 L32.2289,67.8552 C32.3969,67.0432 33.1909,66.5222 34.0009,66.6902 C34.8129,66.8572 35.3339,67.6512 35.1669,68.4622 L29.9019,93.9232 C29.7339,94.7342 28.9409,95.2562 28.1299,95.0882 Z"
          id="Stroke-48"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <g id="Group-52" transform="translate(18.000000, 76.000000)">
          <mask id="mask-18" fill="white">
            <path
              d="M4.213,0.421 L0.418,2.12 L4.316,10.833 L11.577,7.584 L9.229,2.337 C8.598,0.926 7.213,0.089 5.761,0.089 L5.761,0.089 C5.245,0.089 4.716,0.196 4.213,0.421 L4.213,0.421 Z"
              id="path-17"
            />
          </mask>
          <polygon
            id="Fill-50"
            fill="#FFFFFF"
            mask="url(#mask-18)"
            points="-4.582 15.832 16.577 15.832 16.577 -4.911 -4.582 -4.911"
          />
        </g>
        <path
          d="M29.5771,83.5833 L22.3161,86.8323 L18.4181,78.1193 L22.2131,76.4213 C24.1271,75.5643 26.3731,76.4223 27.2291,78.3363 L29.5771,83.5833 Z"
          id="Stroke-53"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <g id="Group-57" transform="translate(25.000000, 62.000000)">
          <mask id="mask-20" fill="white">
            <path
              d="M15.084,2.324 L0.335,43.778 C-0.126,45.08 0.552,46.509 1.854,46.972 L1.854,46.972 C3.154,47.435 4.583,46.756 5.046,45.454 L5.046,45.454 L19.794,3.999 C20.258,2.699 19.578,1.269 18.278,0.806 L18.278,0.806 C18.001,0.707 17.718,0.66 17.439,0.66 L17.439,0.66 C16.411,0.66 15.448,1.3 15.084,2.324 L15.084,2.324 Z"
              id="path-19"
            />
          </mask>
          <polygon
            id="Fill-55"
            fill="#EFF3FA"
            mask="url(#mask-20)"
            points="-4.809 52.118 24.94 52.118 24.94 -4.339 -4.809 -4.339"
          />
        </g>
        <path
          d="M26.8535,108.972 L26.8535,108.972 C28.1545,109.435 29.5835,108.755 30.0465,107.454 L44.7945,65.999 C45.2575,64.699 44.5785,63.269 43.2775,62.806 C41.9765,62.343 40.5465,63.023 40.0835,64.324 L25.3355,105.779 C24.8735,107.079 25.5525,108.509 26.8535,108.972 Z"
          id="Stroke-58"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="42.6162"
          y1="72.1234"
          x2="37.9052"
          y2="70.4474"
          id="Stroke-60"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="38.7842"
          y1="73.9447"
          x2="37.1082"
          y2="78.6557"
          id="Stroke-61"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <g id="Group-64" transform="translate(15.000000, 83.000000)">
          <mask id="mask-22" fill="white">
            <path
              d="M4.45,0.389 C2.382,0.389 0.78,2.194 1.024,4.247 L1.024,4.247 L5.565,42.389 L26.565,42.389 L31.107,4.247 C31.35,2.194 29.749,0.389 27.68,0.389 L27.68,0.389 L4.45,0.389 Z"
              id="path-21"
            />
          </mask>
          <polygon
            id="Fill-62"
            fill="#FFFFFF"
            mask="url(#mask-22)"
            points="-4 47.389 36.131 47.389 36.131 -4.611 -4 -4.611"
          />
        </g>
        <g id="Group-67" transform="translate(15.000000, 83.000000)">
          <mask id="mask-24" fill="white">
            <path
              d="M4.45,0.389 C2.382,0.389 0.78,2.194 1.024,4.247 L1.024,4.247 L5.565,42.39 L26.565,42.39 L27.4,35.389 L16.78,35.389 C14.376,35.389 12.352,33.591 12.067,31.203 L12.067,31.203 L8.4,0.389 L4.45,0.389 Z"
              id="path-23"
            />
          </mask>
          <polygon
            id="Fill-65"
            fill="#EFF3FA"
            mask="url(#mask-24)"
            points="-4 47.389 32.4 47.389 32.4 -4.611 -4 -4.611"
          />
        </g>
        <path
          d="M41.5654,125.389 L20.5654,125.389 L16.0244,87.246 C15.7804,85.194 17.3824,83.389 19.4504,83.389 L42.6804,83.389 C44.7484,83.389 46.3504,85.194 46.1064,87.246 L41.5654,125.389 Z"
          id="Stroke-68"
          stroke="#BBC8DC"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <g id="Group-72" transform="translate(104.000000, 50.000000)">
          <mask id="mask-26" fill="white">
            <path
              d="M0.096,5.889 C0.096,9.056 2.665,11.623 5.831,11.623 L5.831,11.623 C8.998,11.623 11.566,9.056 11.566,5.889 L11.566,5.889 C11.566,2.722 8.998,0.155 5.831,0.155 L5.831,0.155 C2.665,0.155 0.096,2.722 0.096,5.889 L0.096,5.889 Z"
              id="path-25"
            />
          </mask>
          <polygon
            id="Fill-70"
            fill="#FFFFFF"
            mask="url(#mask-26)"
            points="-4.904 16.623 16.565 16.623 16.565 -4.845 -4.904 -4.845"
          />
        </g>
        <g id="Group-32">
          <g id="Group-12" transform="translate(171.000000, 12.000000)">
            <path
              d="M37.4782743,18.0909842 L38.2752743,20.2489842 C38.3062743,20.3319842 38.3742743,20.3939842 38.4602743,20.4159842 L40.6902743,20.9829842 C40.9062743,21.0369842 40.9642743,21.3149842 40.7902743,21.4519842 L38.9842743,22.8779842 C38.9152743,22.9329842 38.8762743,23.0179842 38.8822743,23.1049842 L39.0332743,25.4019842 C39.0462743,25.6229842 38.8022743,25.7649842 38.6172743,25.6409842 L36.7032743,24.3629842 C36.6292743,24.3149842 36.5372743,24.3049842 36.4562743,24.3379842 L34.3182743,25.1899842 C34.1132743,25.2719842 33.9012743,25.0829842 33.9612743,24.8689842 L34.5842743,22.6539842 C34.6092743,22.5689842 34.5882743,22.4779842 34.5332743,22.4089842 L33.0622743,20.6409842 C32.9202743,20.4709842 33.0362743,20.2109842 33.2572743,20.2019842 L35.5572743,20.1109842 C35.6452743,20.1079842 35.7262743,20.0609842 35.7722743,19.9869842 L37.0002743,18.0409842 C37.1192743,17.8539842 37.4012743,17.8829842 37.4782743,18.0909842 Z"
              id="Stroke-188"
              stroke="#BBC8DC"
              strokeWidth="1.5"
            />
            <path
              d="M23.566,5.783 C23.566,6.767 22.768,7.565 21.783,7.565 C20.798,7.565 20,6.767 20,5.783 C20,4.798 20.798,4 21.783,4 C22.768,4 23.566,4.798 23.566,5.783 Z"
              id="Stroke-190"
              stroke="#BBC8DC"
              strokeWidth="1.5"
            />
            <path
              d="M38.727283,0.00158415743 L34.240283,0.490584157 C34.040283,0.512584157 33.934283,0.737584157 34.044283,0.905584157 L36.313283,4.34658416 C36.423283,4.51558416 36.673283,4.50658416 36.772283,4.33158416 L38.991283,0.401584157 C39.098283,0.210584157 38.945283,-0.0214158426 38.727283,0.00158415743 Z"
              id="Stroke-196"
              stroke="#BBC8DC"
              strokeWidth="1.5"
            />
            <path
              d="M6.01668405,0.07 L0.0896840533,5.385 C-0.0603159467,5.518 -0.0153159467,5.764 0.173684053,5.836 L0.173684053,5.836 L6.96268405,8.436 C7.14968405,8.507 7.34768405,8.355 7.32668405,8.155 L7.32668405,8.155 L6.46468405,0.241 C6.44668405,0.094 6.32268405,0 6.19468405,0 L6.19468405,0 C6.13268405,0 6.06868405,0.022 6.01668405,0.07 L6.01668405,0.07 Z"
              id="Mask"
              fill="#E3E9F3"
            />
            <path
              d="M38.3581729,40.011 L35.6991729,40.301 C34.6551729,40.415 33.8981729,41.354 34.0111729,42.401 L34.0111729,42.401 L34.3011729,45.06 C34.4151729,46.105 35.3561729,46.861 36.4011729,46.748 L36.4011729,46.748 L39.0601729,46.457 C40.1071729,46.343 40.8611729,45.403 40.7481729,44.357 L40.7481729,44.357 L40.4571729,41.698 C40.3511729,40.722 39.5271729,40 38.5681729,40 L38.5681729,40 C38.4991729,40 38.4271729,40.003 38.3581729,40.011 L38.3581729,40.011 Z"
              id="Clip-202"
              fill="#E3E9F3"
            />
            <path
              fill="#E3E9F3"
              d="M48.415,6.072 L46.262,7.314 C46.1,7.408 46,7.581 46,7.768 L46,7.768 L46,10.254 C46,10.441 46.1,10.614 46.262,10.707 L46.262,10.707 L48.415,11.95 C48.576,12.044 48.777,12.044 48.939,11.95 L48.939,11.95 L51.091,10.707 C51.253,10.614 51.353,10.441 51.353,10.254 L51.353,10.254 L51.353,7.768 C51.353,7.581 51.253,7.408 51.091,7.314 L51.091,7.314 L48.939,6.072 C48.858,6.025 48.767,6 48.677,6 L48.677,6 C48.586,6 48.496,6.025 48.415,6.072 L48.415,6.072 Z"
              id="path-27"
            />
          </g>
          <g
            id="Group-12-Copy"
            transform="translate(20.500000, 28.000000) scale(-1, -1) translate(-20.500000, -28.000000) "
          >
            <path
              d="M26.5858238,0.403928375 L27.3828238,2.56192837 C27.4138238,2.64492837 27.4818238,2.70692837 27.5678238,2.72892837 L29.7978238,3.29592837 C30.0138238,3.34992837 30.0718238,3.62792837 29.8978238,3.76492837 L28.0918238,5.19092837 C28.0228238,5.24592837 27.9838238,5.33092837 27.9898238,5.41792837 L28.1408238,7.71492837 C28.1538238,7.93592837 27.9098238,8.07792837 27.7248238,7.95392837 L25.8108238,6.67592837 C25.7368238,6.62792837 25.6448238,6.61792837 25.5638238,6.65092837 L23.4258238,7.50292837 C23.2208238,7.58492837 23.0088238,7.39592837 23.0688238,7.18192837 L23.6918238,4.96692837 C23.7168238,4.88192837 23.6958238,4.79092837 23.6408238,4.72192837 L22.1698238,2.95392837 C22.0278238,2.78392837 22.1438238,2.52392837 22.3648238,2.51492837 L24.6648238,2.42392837 C24.7528238,2.42092837 24.8338238,2.37392837 24.8798238,2.29992837 L26.1078238,0.353928375 C26.2268238,0.166928375 26.5088238,0.195928375 26.5858238,0.403928375 Z"
              id="Stroke-188"
              stroke="#BBC8DC"
              strokeWidth="1.5"
            />
            <path
              d="M24.1150743,38.0469842 C24.1150743,39.0309842 23.3170743,39.8289842 22.3320743,39.8289842 C21.3470743,39.8289842 20.5490743,39.0309842 20.5490743,38.0469842 C20.5490743,37.0619842 21.3470743,36.2639842 22.3320743,36.2639842 C23.3170743,36.2639842 24.1150743,37.0619842 24.1150743,38.0469842 Z"
              id="Stroke-190"
              stroke="#BBC8DC"
              strokeWidth="1.5"
            />
            <path
              d="M39.8698743,24.0015842 L35.3828743,24.4905842 C35.1828743,24.5125842 35.0768743,24.7375842 35.1868743,24.9055842 L37.4558743,28.3465842 C37.5658743,28.5155842 37.8158743,28.5065842 37.9148743,28.3315842 L40.1338743,24.4015842 C40.2408743,24.2105842 40.0878743,23.9785842 39.8698743,24.0015842 Z"
              id="Stroke-196"
              stroke="#BBC8DC"
              strokeWidth="1.5"
            />
            <path
              d="M6.01668405,36.07 L0.0896840533,41.385 C-0.0603159467,41.518 -0.0153159467,41.764 0.173684053,41.836 L0.173684053,41.836 L6.96268405,44.436 C7.14968405,44.507 7.34768405,44.355 7.32668405,44.155 L7.32668405,44.155 L6.46468405,36.241 C6.44668405,36.094 6.32268405,36 6.19468405,36 L6.19468405,36 C6.13268405,36 6.06868405,36.022 6.01668405,36.07 L6.01668405,36.07 Z"
              id="Mask"
              fill="#E3E9F3"
            />
            <path
              d="M25.3581729,18.011 L22.6991729,18.301 C21.6551729,18.415 20.8981729,19.354 21.0111729,20.401 L21.0111729,20.401 L21.3011729,23.06 C21.4151729,24.105 22.3561729,24.861 23.4011729,24.748 L23.4011729,24.748 L26.0601729,24.457 C27.1071729,24.343 27.8611729,23.403 27.7481729,22.357 L27.7481729,22.357 L27.4571729,19.698 C27.3511729,18.722 26.5271729,18 25.5681729,18 L25.5681729,18 C25.4991729,18 25.4271729,18.003 25.3581729,18.011 L25.3581729,18.011 Z"
              id="Clip-202"
              fill="#E3E9F3"
            />
            <path
              fill="#E3E9F3"
              d="M30.062,50.0515 L27.909,51.2935 C27.747,51.3875 27.647,51.5605 27.647,51.7475 L27.647,51.7475 L27.647,54.2335 C27.647,54.4205 27.747,54.5935 27.909,54.6865 L27.909,54.6865 L30.062,55.9295 C30.223,56.0235 30.424,56.0235 30.586,55.9295 L30.586,55.9295 L32.738,54.6865 C32.9,54.5935 33,54.4205 33,54.2335 L33,54.2335 L33,51.7475 C33,51.5605 32.9,51.3875 32.738,51.2935 L32.738,51.2935 L30.586,50.0515 C30.505,50.0045 30.414,49.9795 30.324,49.9795 L30.324,49.9795 C30.233,49.9795 30.143,50.0045 30.062,50.0515 L30.062,50.0515 Z"
              id="path-29"
            />
          </g>
        </g>
        <path
          d="M121.4386,61.5305 L121.4386,82.8135 C121.4386,83.9155 120.1946,84.5535 119.2996,83.9155 L109.1756,76.6905 L99.3936,83.8625 C98.5016,84.5185 97.2406,83.8805 97.2406,82.7715 L97.2406,61.5305"
          id="Stroke-209"
          stroke="#BA6BE2"
          strokeWidth="2"
          fill="#FFFFFF"
          strokeLinecap="round"
        />
        <path
          d="M109.212,34 C119.254,34 127.424,42.17 127.424,52.213 C127.424,62.255 119.254,70.426 109.212,70.426 C99.17,70.426 91,62.255 91,52.213 C91,42.17 99.17,34 109.212,34 Z M109.7628,44.4455 C109.5308,44.0045 108.8908,44.0215 108.6828,44.4755 L108.6828,44.4755 L106.5888,49.0265 C106.5058,49.2075 106.3398,49.3355 106.1448,49.3685 L106.1448,49.3685 L101.2088,50.2245 C100.7158,50.3095 100.5358,50.9215 100.9028,51.2595 L100.9028,51.2595 L104.5848,54.6585 C104.7308,54.7925 104.8008,54.9895 104.7718,55.1865 L104.7718,55.1865 L104.0608,60.1455 C103.9908,60.6395 104.5158,61.0005 104.9518,60.7565 L104.9518,60.7565 L109.3218,58.3045 C109.4938,58.2075 109.7028,58.2025 109.8808,58.2895 L109.8808,58.2895 L114.3778,60.4985 C114.8258,60.7175 115.3318,60.3295 115.2348,59.8405 L115.2348,59.8405 L114.2528,54.9265 C114.2138,54.7325 114.2738,54.5315 114.4118,54.3895 L114.4118,54.3895 L117.9028,50.7965 C118.2498,50.4375 118.0358,49.8355 117.5398,49.7775 L117.5398,49.7775 L112.5648,49.1925 C112.3668,49.1695 112.1958,49.0505 112.1018,48.8765 L112.1018,48.8765 Z"
          id="Combined-Shape"
          stroke="#BA6BE2"
          strokeWidth="2"
          fill="#F1D7FF"
        />
      </g>
    </g>
  </svg>
);
