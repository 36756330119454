export const JigsawIcon = ({
  width = '141px',
  height = '122px',
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 130 150"
    aria-label="Jigsaw Icon"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="jigsaw-icon-mask" fill="white">
        <polygon points="0.0335 0.725 105.0335 0.725 105.0335 101.7247 0.0335 101.7247" />
      </mask>
      <path
        d="M91.0335,0.7247 L66.9465,0.7247 L53.5175,0.7247 L27.2255,0.7247 L14.0335,0.7247 C6.3345,0.7247 0.0335,7.0247 0.0335,14.7247 L0.0335,36.2897 L0.0335,50.2147 L0.0335,52.2147 L0.0335,75.4667 L0.0335,87.7247 C0.0335,95.4247 6.3345,101.7247 14.0335,101.7247 L51.5105,101.7247 L51.4235,92.7897 L58.2265,92.7897 C63.0345,92.7897 66.9465,88.8777 66.9465,84.0697 C66.9465,79.2627 63.0345,75.3507 58.2265,75.3507 L51.4325,75.3507 L51.4325,52.2177 L51.4325,50.2227 L66.9465,50.2227 L72.9425,50.2247 L72.9425,45.4307 C72.9425,39.5197 77.7515,34.7117 83.6625,34.7117 C89.5735,34.7117 94.3825,39.5197 94.3825,45.4307 L94.3825,50.2247 L105.0335,50.2167 L105.0335,14.7247 C105.0335,7.0247 98.7335,0.7247 91.0335,0.7247"
        fill="#EAF0FA"
        mask="url(#jigsaw-icon-mask)"
      />
      <path
        d="M130.3938,108.2062 L139.4408,74.8542 L124.8148,70.9142 L126.6138,64.2542 C127.2098,62.0422 126.9108,59.7292 125.7678,57.7432 C124.6268,55.7572 122.7808,54.3342 120.5678,53.7372 C118.3548,53.1392 116.0428,53.4392 114.0568,54.5812 C112.0698,55.7232 110.6468,57.5702 110.0498,59.7822 L108.2508,66.4422 L87.5358,60.8432 L82.3688,79.8212 L86.8698,81.0362 C89.6588,81.7902 91.9868,83.5822 93.4268,86.0872 C94.8678,88.5922 95.2448,91.5072 94.4928,94.2962 C93.7388,97.0842 91.9448,99.4132 89.4408,100.8522 C86.9368,102.2922 84.0208,102.6702 81.2328,101.9172 L76.7368,100.7032 L74.3778,109.6002 L110.8418,119.4452 C119.3448,121.7402 128.0978,116.7082 130.3938,108.2062 Z"
        stroke="#031D44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="6"
      />
      <path
        d="M1.0003,51.2121 L20.6553,51.2121 L20.6553,57.0061 C20.6553,62.3741 25.0073,66.7261 30.3753,66.7261 C35.7433,66.7261 40.0953,62.3741 40.0953,57.0061 L40.0953,51.2121 L52.3813,51.2221 L52.3813,32.4351 L46.5873,32.4351 C41.2193,32.4351 36.8673,28.0841 36.8673,22.7151 C36.8673,17.3481 41.2193,12.9961 46.5873,12.9961 L52.3813,12.9961 L52.4813,1.8101"
        stroke="#031D44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
