import { Box } from 'braid-design-system';
import type { ReactNode, ComponentProps } from 'react';

import { FEATURE_APR_NVL, useFeatureToggleData } from '../../featureToggle';

type BoxProps = ComponentProps<typeof Box>;

export interface RoundedCardProps extends BoxProps {
  children: ReactNode;
}

export const RoundedCard = ({ children, ...otherProps }: RoundedCardProps) => {
  const isAPRNVLEnabled = useFeatureToggleData(FEATURE_APR_NVL, false);
  return (
    <Box
      background="surface"
      borderRadius={isAPRNVLEnabled ? 'large' : 'none'}
      padding={isAPRNVLEnabled ? 'large' : 'none'}
      position="relative"
      {...otherProps}
    >
      {children}
    </Box>
  );
};
