import { useTranslations } from '@vocab/react';
import { Alert, Notice, Text } from 'braid-design-system';

import { FEATURE_APR_NVL, useFeatureToggleData } from '../../featureToggle';

import translations from './.vocab';

export const WidgetError = () => {
  const { t } = useTranslations(translations);
  const isAPRNVLEnabled = useFeatureToggleData(FEATURE_APR_NVL, false);

  if (isAPRNVLEnabled) {
    return (
      <Notice tone="critical">
        <Text>
          {t(
            "We can't load this right now. Refresh the page or check back later.",
          )}
        </Text>
      </Notice>
    );
  }

  return (
    <Alert tone="critical">
      <Text>
        {t(
          "We can't load this right now, but we're doing our best to fix it. Refresh the page or check back later.",
        )}
      </Text>
    </Alert>
  );
};
