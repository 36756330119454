import { EmployerFooter, type AnalyticsEvent } from '@seek/adv-header-footer';
import { type HTMLProps, useCallback } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { getEnvironment, getLocale, getSite } from '../../config';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { trackLink } from '../../utils/tealiumEventTracker';

export const AdCentreFooter = ({
  adCentreAccountInfo,
  currentPage,
}: {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  currentPage: string;
}) => {
  const { country, language, zone } = useConfig();

  const basicPageTrackingData = {
    siteCountry: country,
    currentPage,
    hirerUserEmail:
      adCentreAccountInfo?.headerFooterContext.identityContext?.email || null,
    hirerUserFullName:
      adCentreAccountInfo?.headerFooterContext?.user?.name || null,
    siteSection: 'jobs',
    userId: adCentreAccountInfo?.headerFooterContext?.user?.id || null,
    siteSubsection: 'analytics',
    zone,
    siteLanguage: language,
    brand: 'seek',
  };

  const linkRenderer = useCallback(
    (origin: string) => (props: HTMLProps<HTMLAnchorElement>) => {
      if (origin) {
        return <a {...props} data-analytics-origin={origin} />;
      }
      return <a {...props} />;
    },
    [],
  );

  const isStaging = getEnvironment() === 'staging';

  const onTrackedItemInteraction = (analyticsEventData: AnalyticsEvent) => {
    trackLink(analyticsEventData.eventName, {
      ...basicPageTrackingData,
      ...analyticsEventData,
    });
  };

  return (
    <EmployerFooter
      linkRenderer={linkRenderer('global-header')}
      locale={getLocale()}
      site={getSite()}
      isStaging={isStaging}
      onTrackedItemInteraction={onTrackedItemInteraction}
      {...adCentreAccountInfo?.headerFooterContext}
    />
  );
};
