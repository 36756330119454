import { useResponsiveValue } from 'braid-design-system';

export const useTabletOnlyView = () => {
  const responsiveValue = useResponsiveValue();

  const isTabletOnly = responsiveValue({
    mobile: false,
    tablet: true,
    desktop: false,
  });

  return isTabletOnly;
};
