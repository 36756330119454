import { useTranslations } from '@vocab/react';
import { Stack, Text, TextLink } from 'braid-design-system';

import { useConfig } from '../../App/ConfigContext';
import { NoAccessIconNvl } from '../../assets/icons/NoAccessIcon/NoAccessIconNvl';
import { PageErrorNvl } from '../PageError/PageErrorNvl';

import translations from './.vocab';

export const NoAccessNvl = () => {
  const { t } = useTranslations(translations);
  const { urlResolver } = useConfig();

  return (
    <PageErrorNvl
      icon={<NoAccessIconNvl />}
      title={t('No access page title')}
      description={t('No access page description nvl', {
        TextLink: (children) => (
          <TextLink href={urlResolver('/contactus')} target="_blank">
            {children}
          </TextLink>
        ),
        Stack: (children: ReactNodeNoStrings) => (
          <Stack space="xsmall">{children}</Stack>
        ),
        Text: (children) => <Text>{children}</Text>,
      })}
    />
  );
};
