import { useTranslations } from '@vocab/react';
import { Box, Inline, Stack, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

import { useMobileView } from '../../hooks/useMobileView';
import { formatNumber } from '../../utils/number';
import { formatPercentage } from '../../utils/percentage/percentageFormatter';
import {
  ClickableTooltip,
  type ClickableTooltipProps,
} from '../ClickableTooltip/ClickableTooltip';
import { Medium } from '../Medium/Medium';
import { Metric } from '../Metric/Metric';
import { Trend } from '../Trend/Trend';

import translations from './.vocab';
import { mapComparisonRateToTrendParts } from './AdStatCard.formatters';

export interface AdStatCardProps {
  dataId?: string;
  adRatingConfidence: string | null;
  statCount: number;
  conversionLabel?: ReactNode;
  title: string;
  mobileInPercentage: number;
  comparisonToSimilarAds: number | null;
  similarAdsAverage: number | null;
  displayMarketPerformance: boolean;
  tooltip: ClickableTooltipProps;
}

export const AdStatCardNvl = ({
  dataId,
  adRatingConfidence,
  statCount,
  conversionLabel,
  title,
  mobileInPercentage,
  comparisonToSimilarAds,
  similarAdsAverage,
  displayMarketPerformance,
  tooltip,
}: AdStatCardProps) => {
  const { t } = useTranslations(translations);

  const similarAdsTooltip = () => {
    const tip =
      adRatingConfidence === 'LOW'
        ? 'Not enough roles in location Nvl'
        : 'Performance compared to recent ads Nvl';
    return <Text size="standard">{t(tip)}</Text>;
  };

  const renderSimilarAdsComparison = () => {
    if (comparisonToSimilarAds === null) {
      return null;
    }

    if (comparisonToSimilarAds === 0) {
      return (
        <Text size="standard" weight="regular">
          {t('Equal to similar ads without tooltip')}
        </Text>
      );
    }

    const similarAdsTrend = mapComparisonRateToTrendParts(
      comparisonToSimilarAds,
    );

    return (
      <Text size="standard" weight="regular">
        {t(`${similarAdsTrend.metricSuffix} similar ads without tooltip`, {
          metric: similarAdsTrend.metricLabel,
          Trend: (label) => (
            <Trend
              isPositiveTrend={similarAdsTrend.isPositiveTrend}
              isInlineTrend={similarAdsTrend.isInlineTrend}
              showTrendArrow={false}
              size="standard"
            >
              {label}
            </Trend>
          ),
        })}
      </Text>
    );
  };

  const isMobile = useMobileView();

  return (
    <Box
      data-id={dataId}
      background="surface"
      borderRadius="large"
      boxShadow={isMobile ? undefined : 'borderNeutralLight'}
      padding={isMobile ? 'none' : 'large'}
      height="full"
    >
      <Stack space="medium">
        <Inline space="xsmall" alignY="center" align="left">
          <Text size="standard" weight="strong" maxLines={1}>
            {title}
          </Text>
          {tooltip ? (
            <ClickableTooltip
              id={tooltip.id}
              title={tooltip.title ?? title}
              contents={tooltip.contents}
            />
          ) : null}
        </Inline>
        <Stack space="medium">
          <Metric
            metric={statCount}
            metricNotation="compact"
            metricSize="medium"
          />
          <Stack space="small">
            <Text>
              {t('{percentage} from mobile', {
                percentage: formatPercentage(mobileInPercentage, 0),
                Medium: (text) => <Medium>{text}</Medium>,
              })}
            </Text>
            {Boolean(conversionLabel) ? (
              <Text>{conversionLabel}</Text>
            ) : (
              // This creates the empty space to align with the other stats
              <Text>&nbsp;</Text>
            )}
          </Stack>
        </Stack>
      </Stack>
      {displayMarketPerformance && similarAdsAverage !== null && (
        <Box paddingTop="xlarge">
          <Stack space="small" align="left">
            <Inline space="xsmall" alignY="center" align="left">
              <Text size="small" weight="strong">
                {t('Market performance')}
              </Text>
              <ClickableTooltip
                id="similar-ads-tooltip"
                title={t('Market performance')}
                contents={similarAdsTooltip()}
              />
            </Inline>
            <Stack space="xsmall">
              {renderSimilarAdsComparison()}
              <Text size="standard">
                {t(`Similar ads average {average}`, {
                  average: formatNumber(similarAdsAverage, {
                    notation: 'compact',
                  }),
                  Medium: (value) => <Medium>{value}</Medium>,
                })}
              </Text>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
