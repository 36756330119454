import { type Country, createAbsoluteUrl } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Badge,
  Box,
  Column,
  Columns,
  Divider,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import { useCallback, type ReactNode } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { ElapsedTime } from '../../components/ElapsedTime/ElapsedTime';
import type { SimilarAdsDetails } from '../../gql/generated';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { GenericTitleContainer } from '../GenericTitleContainer/GenericTitleContainer';

import localTranslations from './.vocab';

import * as styles from './SimilarAds.css';

export interface SimilarAdsProps {
  title: ReactNode;
  sortedAds: SimilarAdsDetails[];
}

const productDisplayNameMap: Record<
  string,
  'BRANDED' | 'PREMIUM' | 'STANDOUT'
> = {
  BRANDED: 'BRANDED',
  PREMIUM: 'PREMIUM',
  STANDOUT: 'STANDOUT',
};

export const SimilarAds = ({ title, sortedAds }: SimilarAdsProps) => {
  const { t } = useTranslations(localTranslations);
  const { country, language } = useConfig();

  const adBadgesFromAd = (ad: SimilarAdsDetails): string[] =>
    [
      productDisplayNameMap[ad.productDisplayName]
        ? [t(`Badge ${productDisplayNameMap[ad.productDisplayName]}`)]
        : [],
      ad.hasKeySellingPoints ? [t('Badge key selling points')] : [],
      !ad.isSalaryHidden ? [t('Badge salary displayed')] : [],
    ].flat();

  const similarJobUrl = createAbsoluteUrl({
    product: 'candidate',
    country: country as Country,
    language,
    path: `/expiredjob/`,
  });

  const onSimilarAdClicked = useCallback(() => {
    trackEvent('similar_ad_pressed', {
      objectInteraction: 'apr-similar-ad',
    });
  }, []);

  return (
    <GenericTitleContainer title={title} weight="weak">
      <Box marginLeft={['medium', 'none', 'none']}>
        <Stack space="medium">
          {sortedAds.map((ad, index) => {
            const advertiserName = ad.isPrivateAdvertiser
              ? t('Private Advertiser')
              : ad.advertiserName;

            return (
              <Box key={index} data={{ id: 'ui-similar-ads-section-ad' }}>
                <Stack space="medium">
                  <Columns
                    space="gutter"
                    alignY="center"
                    collapseBelow="tablet"
                  >
                    <Column width="1/5">
                      <Box>
                        {!ad.isPrivateAdvertiser && ad.serpLogoUrl ? (
                          <img
                            className={styles.logo}
                            src={ad.serpLogoUrl}
                            alt={advertiserName}
                            title={advertiserName}
                          />
                        ) : (
                          <Box title={advertiserName}>
                            <Text
                              size="small"
                              maxLines={1}
                              weight="medium"
                              tone="secondary"
                            >
                              {advertiserName}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Column>
                    <Column>
                      <Columns space="gutter" collapseBelow="desktop">
                        <Column>
                          <Stack space="medium">
                            <Text maxLines={1} tone="formAccent">
                              <TextLink
                                data={{
                                  id: `ui-similar-ads-row-${index}-link`,
                                }}
                                href={`${similarJobUrl}${ad.jobId}`}
                                target="_blank"
                                title={ad.jobTitle}
                                onClick={onSimilarAdClicked}
                              >
                                {ad.jobTitle}
                              </TextLink>
                            </Text>
                            <Stack space="small">
                              <Box title={ad.jobLocationLabel || ''}>
                                <Text maxLines={1}>{ad.jobLocationLabel}</Text>
                              </Box>
                              <Text>
                                <ElapsedTime
                                  pastTime={ad.jobCreatedTimestampUTC}
                                  showTitle={true}
                                  id="ui-similar-ads-section-created-date"
                                >
                                  {({ renderProps }) =>
                                    t(`Posted ${renderProps.type}`, {
                                      value: renderProps.value,
                                    })
                                  }
                                </ElapsedTime>
                              </Text>
                            </Stack>
                          </Stack>
                        </Column>
                        <Column width="content">
                          <Stack space="xxsmall">
                            {adBadgesFromAd(ad).map((adBadge, i) => (
                              <Box
                                title={adBadge}
                                key={`ui-similar-ads-badge-${i}`}
                                className={styles.truncatePill}
                              >
                                <Badge tone="neutral">{adBadge}</Badge>
                              </Box>
                            ))}
                          </Stack>
                        </Column>
                      </Columns>
                    </Column>
                  </Columns>
                  {index < sortedAds?.length - 1 ? <Divider /> : null}
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </GenericTitleContainer>
  );
};
