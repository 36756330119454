import { Box, IconChevron, Heading } from 'braid-design-system';
import { vars } from 'braid-design-system/css';

import { RoundedCard } from '../RoundedCard/RoundedCard';

export interface AccordionSectionProps {
  children: ReactNodeNoStrings;
  id?: string;
  title: string;
  isExpanded: boolean;
  onExpand: (isExpanded: boolean) => void;
}

export const AccordionSectionNvl = ({
  children,
  id,
  title,
  isExpanded,
  onExpand,
}: AccordionSectionProps) => {
  const handleAccordianHeaderClick = () => {
    onExpand(!isExpanded);
  };

  return (
    <Box id={id}>
      <RoundedCard
        cursor="pointer"
        padding="none"
        data-id="ui-accordion-section-button"
        onClick={handleAccordianHeaderClick}
      >
        <Box gap="small" display="flex">
          <Heading level="3" weight="regular">
            {title}
          </Heading>
          <div>
            <IconChevron direction={isExpanded ? 'up' : 'down'} size="small" />
          </div>
        </Box>
      </RoundedCard>
      {isExpanded && (
        <>
          <Box style={{ height: vars.space.large }} />
          <RoundedCard boxShadow="borderNeutralLight" padding="large">
            {children}
          </RoundedCard>
        </>
      )}
    </Box>
  );
};
