import { useAuth } from '../../hooks/useAuth';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { AdCentreHeader } from '../AdCentreHeader/AdCentreHeader';

export interface HeaderProps {
  activeTabId?: 'analytics';
  activeSubTabId?: 'adUsage';
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  hirerId: string;
  currentPage: string;
}

export const Header = ({
  activeTabId = 'analytics',
  activeSubTabId,
  adCentreAccountInfo,
  hirerId,
  currentPage,
}: HeaderProps) => {
  const {
    // error: authenticationError,
    isLoading: isLoadingAuth,
  } = useAuth();

  if (isLoadingAuth) {
    return null;
  }

  const accountNumber =
    adCentreAccountInfo?.headerFooterContext.advertiser?.billingId || '';
  const advertiserName =
    adCentreAccountInfo?.headerFooterContext.advertiser?.name || 'SEEK';

  return (
    <AdCentreHeader
      activeTabId={activeTabId}
      activeSubTabId={activeSubTabId}
      adCentreAccountInfo={adCentreAccountInfo}
      accountNumber={accountNumber}
      advertiserId={hirerId}
      advertiserName={advertiserName}
      currentPage={currentPage}
    />
  );
};
