import { useTranslations } from '@vocab/react';
import { Box, Divider, Stack, Text } from 'braid-design-system';
import type React from 'react';

import { NoRecommendationIconNvl } from '../../assets/icons/NoRecommendationIcon/NoRecommendationIconNvl';

import localTranslations from './.vocab';

export const NoRecommendationNvl = () => {
  const { t } = useTranslations(localTranslations);

  return (
    <Stack space="medium" align="center">
      <Divider />
      <Box padding="large">
        <Stack space="medium" align="center">
          <NoRecommendationIconNvl />
          <Text size="large" weight="medium">
            {t("We don't have any suggestions to improve this ad.")}
          </Text>
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
};
