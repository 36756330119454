import { useTranslations } from '@vocab/react';
import {
  Stack,
  Heading,
  Columns,
  Column,
  Box,
  IconChevron,
  Divider,
} from 'braid-design-system';
import { useState } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { PageSectionTracker } from '../../components/PageSectionTracker/PageSectionTracker';
import { PrimarySection } from '../../components/PrimarySection/PrimarySection';
import { useHasAdRating } from '../../context/hasAdRating';
import { useMobileView } from '../../hooks/useMobileView';
import { useTabletOnlyView } from '../../hooks/useTabletOnlyView';
import { ApplicationPerformanceFallback } from '../../widgets/ApplicationPerformance/ApplicationPerformanceFallback';
import { ApplicationPerformanceNvl } from '../../widgets/ApplicationPerformance/ApplicationPerformanceNvl';
import { ClicksOnYourAdFallback } from '../../widgets/ClicksOnYourAd/ClicksOnYourAdFallback';
import { ClicksOnYourAdNvl } from '../../widgets/ClicksOnYourAd/ClicksOnYourAdNvl';
import { PerformancePredictionFallback } from '../../widgets/PerformancePrediction/PerformancePredictionFallback';
import { PerformancePredictionNvl } from '../../widgets/PerformancePrediction/PerformancePredictionNvl';
import { ViewsInJobSearchFallback } from '../../widgets/ViewsInJobSearch/ViewsInJobSearchFallback';
import { ViewsInJobSearchNvl } from '../../widgets/ViewsInJobSearch/ViewsInJobSearchNvl';

import translations from './.vocab';

export const MoreStatsNvl = () => {
  const { t } = useTranslations(translations);
  const { hasAdRating, updateHasAdRating } = useHasAdRating();

  const [hasPerformancePredictionError, setHasPerformancePredictionError] =
    useState(false);

  const handlePerformancePredictionError = () => {
    setHasPerformancePredictionError(true);
  };

  const FunnelIcon = (
    <Box paddingTop="xxlarge">
      <IconChevron direction="right" tone="secondary" />
    </Box>
  );

  const isMobile = useMobileView();

  const isTabletOnly = useTabletOnlyView();

  return (
    <PrimarySection data-id="ui-stats-section">
      <PageSectionTracker
        eventName="ad_performance_page_section_displayed"
        props={{
          currentPageSection: 'see more stats',
          objectInteraction: 'apr-section-see-more-stats',
        }}
      />
      <Stack space="large">
        <Heading level="3" weight="regular">
          {t('More stats')}
        </Heading>
        <Stack space="large">
          <Box
            overflow={isTabletOnly ? 'scroll' : undefined}
            style={{ overflowY: isTabletOnly ? 'hidden' : undefined }}
          >
            <Columns
              space={isMobile ? 'large' : 'xxsmall'}
              collapseBelow="tablet"
            >
              {isMobile ? <Divider /> : null}
              <Column
                data={{ id: 'first-column' }}
                width={isTabletOnly ? '2/5' : undefined}
              >
                <ErrorBoundary fallback={<ViewsInJobSearchFallback />}>
                  <ViewsInJobSearchNvl updateHasAdRating={updateHasAdRating} />
                </ErrorBoundary>
              </Column>
              {!isTabletOnly ? (
                <Column width="content">
                  {isMobile ? <Divider /> : FunnelIcon}
                </Column>
              ) : null}
              <Column
                data={{ id: 'second-column' }}
                width={isTabletOnly ? '2/5' : undefined}
              >
                <ErrorBoundary fallback={<ClicksOnYourAdFallback />}>
                  <ClicksOnYourAdNvl updateHasAdRating={updateHasAdRating} />
                </ErrorBoundary>
              </Column>
              {!isTabletOnly ? (
                <Column width="content">
                  {isMobile ? <Divider /> : FunnelIcon}
                </Column>
              ) : null}
              <Column
                data={{ id: 'third-column' }}
                width={isTabletOnly ? '2/5' : undefined}
              >
                <ErrorBoundary fallback={<ApplicationPerformanceFallback />}>
                  <ApplicationPerformanceNvl
                    updateHasAdRating={updateHasAdRating}
                  />
                </ErrorBoundary>
              </Column>
            </Columns>
            {isMobile ? <Divider /> : null}
          </Box>

          {hasAdRating && (
            <Box
              data={{ id: 'ui-application-performance-section' }}
              borderRadius="large"
              height="full"
              boxShadow={isMobile ? undefined : 'borderNeutralLight'}
            >
              {hasPerformancePredictionError ? (
                <PerformancePredictionFallback />
              ) : (
                <Box width="full">
                  <ErrorBoundary
                    onError={handlePerformancePredictionError}
                    fallback={<PerformancePredictionFallback />}
                  >
                    <PerformancePredictionNvl
                      onQueryError={handlePerformancePredictionError}
                    />
                  </ErrorBoundary>
                </Box>
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </PrimarySection>
  );
};
