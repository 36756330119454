
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiVmlldy10by1jbGljayByYXRlIGZvcm11bGEiOiJWaWV3LXRvLWNsaWNrIHJhdGUgPSBBZCBjbGlja3MgLyB2aWV3cyBpbiBqb2Igc2VhcmNoLiIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoi4LiV4Lix4Lin4Lit4Lii4LmI4Liy4LiH4LiC4Lit4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmB4Liq4LiU4LiH4LmD4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZ4LiC4Lit4LiHIFNFRUsifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiVmlldy10by1jbGljayByYXRlIGZvcm11bGEiOiJWaWV3LXRvLWNsaWNrIHJhdGUgPSBBZCBjbGlja3MgLyB2aWV3cyBpbiBqb2Igc2VhcmNoLiIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoi4LiV4Lix4Lin4Lit4Lii4LmI4Liy4LiH4LiC4Lit4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmB4Liq4LiU4LiH4LmD4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZ4LiC4Lit4LiHIFNFRUsifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gZGlrb252ZXJzaSBtZW5qYWRpIGp1bWxhaCBrbGlrIHBhZGEgaWtsYW4iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJDdXBsaWthbiBpa2xhbiBBbmRhIG11bmN1bCBkaSBwZW5jYXJpYW4gbG93b25nYW4ga2VyamEgU0VFSy4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gZGlrb252ZXJzaSBtZW5qYWRpIGp1bWxhaCBrbGlrIHBhZGEgaWtsYW4iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJDdXBsaWthbiBpa2xhbiBBbmRhIG11bmN1bCBkaSBwZW5jYXJpYW4gbG93b25nYW4ga2VyamEgU0VFSy4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiVmlldy10by1jbGljayByYXRlIGZvcm11bGEiOiJWaWV3LXRvLWNsaWNrIHJhdGUgPSBBZCBjbGlja3MgLyB2aWV3cyBpbiBqb2Igc2VhcmNoLiIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoi4LiV4Lix4Lin4Lit4Lii4LmI4Liy4LiH4LiC4Lit4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmB4Liq4LiU4LiH4LmD4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZ4LiC4Lit4LiHIFNFRUsifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiVmlldy10by1jbGljayByYXRlIGZvcm11bGEiOiJWaWV3LXRvLWNsaWNrIHJhdGUgPSBBZCBjbGlja3MgLyB2aWV3cyBpbiBqb2Igc2VhcmNoLiIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoi4LiV4Lix4Lin4Lit4Lii4LmI4Liy4LiH4LiC4Lit4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmB4Liq4LiU4LiH4LmD4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZ4LiC4Lit4LiHIFNFRUsifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gZGlrb252ZXJzaSBtZW5qYWRpIGp1bWxhaCBrbGlrIHBhZGEgaWtsYW4iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJDdXBsaWthbiBpa2xhbiBBbmRhIG11bmN1bCBkaSBwZW5jYXJpYW4gbG93b25nYW4ga2VyamEgU0VFSy4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gZGlrb252ZXJzaSBtZW5qYWRpIGp1bWxhaCBrbGlrIHBhZGEgaWtsYW4iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJDdXBsaWthbiBpa2xhbiBBbmRhIG11bmN1bCBkaSBwZW5jYXJpYW4gbG93b25nYW4ga2VyamEgU0VFSy4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiW+G5vMOsw6zDrOG6veG6veG6vcW1xaEgw6zDrMOs4LiB4Li14LmJIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipXSIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiJbPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4bm9w6zDrMOs4bq94bq94bq9xbUt4bmvw7bDtsO2LcOnxprDrMOsw6zDp8S3IMWZxIPEg8SD4bmv4bq94bq94bq9XSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiWzxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IMOnw7bDtsO24LiB4Li14LmJ4bm94bq94bq94bq9xZnhua/hur3hur3hur3GjCDhua/DtsO2w7YgxIPEg8SDxowgw6fGmsOsw6zDrMOnxLfFoV0iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlvhubzDrMOsw6zhur3hur3hur3FtS3hua/DtsO2w7Ytw6fGmsOsw6zDrMOnxLcgxZnEg8SDxIPhua/hur3hur3hur0gPSDhuqzGjCDDp8aaw6zDrMOsw6fEt8WhIC8g4bm9w6zDrMOs4bq94bq94bq9xbXFoSDDrMOsw6zguIHguLXguYkgxLXDtsO2w7bDnyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiW8Odw7bDtsO2x5rHmseaxZkgxIPEg8SDxowgxaHguIHguLXguYnDrMOsw6zGpcal4bq94bq94bq94bmvIMSDxIPEg8alxqXhur3hur3hur3Eg8SDxIPFmeG6veG6veG6vcaMIMOsw6zDrOC4geC4teC5iSDhua/huKnhur3hur3hur0gxaDDi8OL4biwIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipLl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiW+G5vMOsw6zDrOG6veG6veG6vcW1xaEgw6zDrMOs4LiB4Li14LmJIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipXSIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiJbPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4bm9w6zDrMOs4bq94bq94bq9xbUt4bmvw7bDtsO2LcOnxprDrMOsw6zDp8S3IMWZxIPEg8SD4bmv4bq94bq94bq9XSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiWzxNZWRpdW0+e3BlcmNlbnRhZ2V9PC9NZWRpdW0+IMOnw7bDtsO24LiB4Li14LmJ4bm94bq94bq94bq9xZnhua/hur3hur3hur3GjCDhua/DtsO2w7YgxIPEg8SDxowgw6fGmsOsw6zDrMOnxLfFoV0iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlvhubzDrMOsw6zhur3hur3hur3FtS3hua/DtsO2w7Ytw6fGmsOsw6zDrMOnxLcgxZnEg8SDxIPhua/hur3hur3hur0gPSDhuqzGjCDDp8aaw6zDrMOsw6fEt8WhIC8g4bm9w6zDrMOs4bq94bq94bq9xbXFoSDDrMOsw6zguIHguLXguYkgxLXDtsO2w7bDnyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiW8Odw7bDtsO2x5rHmseaxZkgxIPEg8SDxowgxaHguIHguLXguYnDrMOsw6zGpcal4bq94bq94bq94bmvIMSDxIPEg8alxqXhur3hur3hur3Eg8SDxIPFmeG6veG6veG6vcaMIMOsw6zDrOC4geC4teC5iSDhua/huKnhur3hur3hur0gxaDDi8OL4biwIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipLl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    