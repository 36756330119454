import { Box, Page } from 'braid-design-system';

import { AdUsageHeader } from '../../blocks/AdUsageHeader/AdUsageHeader';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { AdCentreFooter } from '../AdCentreFooter/AdCentreFooter';
import { Header } from '../Header/Header';
import { SystemBanner } from '../SystemBanner/SystemBanner';

export interface AdUsagePageFrameProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  numOfAds?: number;
  children: ReactNodeNoStrings;
}

export const AdUsagePageFrame = ({
  children,
  adCentreAccountInfo,
  numOfAds,
}: AdUsagePageFrameProps) => {
  const hasAccess = Boolean(
    adCentreAccountInfo?.headerFooterContext?.advertiser?.permissionOptions
      ?.canViewManagerReports,
  );

  return (
    <Page
      footerPosition="belowFold"
      footer={
        <AdCentreFooter
          adCentreAccountInfo={adCentreAccountInfo}
          currentPage="ad usage report"
        />
      }
    >
      <Header
        adCentreAccountInfo={adCentreAccountInfo}
        hirerId=""
        activeSubTabId="adUsage"
        currentPage="ad usage report"
      />
      <SystemBanner />
      <AdUsageHeader hasAccess={hasAccess} numOfAds={numOfAds} />
      {children}
      <Box id="modal-base" />
    </Page>
  );
};
