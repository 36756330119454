import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  ButtonIcon,
  ButtonLink,
  Column,
  Columns,
  Heading,
  IconClear,
  Inline,
  Notice,
  Stack,
  Text,
} from 'braid-design-system';
import { useState, type ReactElement, type ReactNode } from 'react';

import { FEATURE_APR_NVL, useFeatureToggleData } from '../../featureToggle';
import { useMobileView } from '../../hooks/useMobileView';
import type { PostTypeCategory } from '../../types/AdPerformanceTypes';
import { Modal } from '../Modal/Modal';
import { SecondarySection } from '../SecondarySection/SecondarySection';

import localTranslations from './.vocab';

export interface RecommendationSkeletonProps {
  children: ReactNodeNoStrings;
  coverTitle?: string;
  coverSubTitle?: string | ReactNode;
  titleContent: string;
  subtitleContent?: ReactNode;
  isExpired?: boolean;
  postTypeCategory?: PostTypeCategory;
  editAdUrl: string;
  coverIcon: ReactElement;
  dataId: string;
  direction?: 'row' | 'column';
  viewButtonVariant?: 'transparent' | 'ghost';
  showEditButton?: boolean;
  showSubTitle?: boolean;
  onEditAdButtonClicked?: () => void;
  onEditAdIndirectButtonClicked?: () => void;
  toggleModalTrackEvent?: (isOpen: boolean) => void;
}

export const RecommendationSkeleton = ({
  children,
  coverTitle,
  coverSubTitle,
  titleContent,
  subtitleContent,
  isExpired,
  postTypeCategory,
  editAdUrl,
  coverIcon,
  dataId,
  direction = 'row',
  // TODO: Remove or update this prop to the correct value
  viewButtonVariant = 'ghost',
  showEditButton = true,
  showSubTitle = true,
  onEditAdButtonClicked,
  onEditAdIndirectButtonClicked,
  toggleModalTrackEvent,
}: RecommendationSkeletonProps) => {
  const { t } = useTranslations(localTranslations);

  const isAPRNVLEnabled = useFeatureToggleData(FEATURE_APR_NVL, false);

  const [showModal, setShowModal] = useState(false);
  const [showEditAdWarningModal, setShowEditAdWarningModal] = useState(false);

  const isMobile = useMobileView();

  const toggleModal = () => {
    if (showEditAdWarningModal) {
      return;
    }
    setShowModal((prevState) => !prevState);

    if (toggleModalTrackEvent) {
      toggleModalTrackEvent(!showModal);
    }
  };

  const onEditIndirectAdClicked = () => {
    if (onEditAdIndirectButtonClicked) {
      onEditAdIndirectButtonClicked();
    }
    setShowEditAdWarningModal(true);
  };

  const onEditAdWarningModalShouldDismiss = () => {
    setShowEditAdWarningModal(false);
  };

  return (
    <SecondarySection
      direction={direction}
      marginBottom={isAPRNVLEnabled ? 'none' : 'small'}
      data-id={dataId}
      icon={
        <Box
          background="infoLight"
          borderRadius="full"
          padding="small"
          marginTop={isAPRNVLEnabled ? 'none' : 'small'}
          style={{ width: isAPRNVLEnabled ? '56px' : undefined }}
        >
          {coverIcon}
        </Box>
      }
      titleComponent={
        <Box>
          <Heading level="4">{coverTitle || titleContent}</Heading>
        </Box>
      }
      subTitleComponent={
        subtitleContent ? (
          <Inline space="xsmall">
            <Text>{coverSubTitle || subtitleContent}</Text>
          </Inline>
        ) : null
      }
      tailComponent={
        <Box
          marginTop={direction === 'column' ? 'large' : 'none'}
          style={{ width: isMobile ? '35%' : undefined }}
        >
          <Button
            variant={isAPRNVLEnabled ? 'solid' : viewButtonVariant}
            onClick={toggleModal}
          >
            {t('View tip')}
          </Button>
        </Box>
      }
    >
      {showModal ? (
        <Modal id="modal-base" onModalShouldDismiss={toggleModal}>
          <Box paddingX="large" paddingY="large">
            <Stack space="medium">
              <Columns space="gutter">
                <Column>
                  <Box
                    component="button"
                    onClick={toggleModal}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconClear size="large" />
                  </Box>
                </Column>
                <Column width="content">
                  {postTypeCategory === 'DIRECT' &&
                  !isExpired &&
                  showEditButton ? (
                    <ButtonLink
                      href={editAdUrl}
                      variant={isAPRNVLEnabled ? 'solid' : viewButtonVariant}
                      onClick={onEditAdButtonClicked}
                    >
                      {t('Edit ad')}
                    </ButtonLink>
                  ) : null}
                  {postTypeCategory === 'INDIRECT' &&
                  !isExpired &&
                  showEditButton ? (
                    <Button
                      variant={isAPRNVLEnabled ? 'solid' : viewButtonVariant}
                      onClick={onEditIndirectAdClicked}
                    >
                      {t('Edit ad')}
                    </Button>
                  ) : null}
                </Column>
              </Columns>
              <Stack space="large">
                <Stack space="medium">
                  <Heading level="4">{titleContent}</Heading>
                  {showSubTitle && <Text>{subtitleContent}</Text>}
                </Stack>
                <Stack space="xlarge">{children}</Stack>
              </Stack>
            </Stack>
            {showEditAdWarningModal && (
              <Modal
                id="modal-base"
                onModalShouldDismiss={onEditAdWarningModalShouldDismiss}
              >
                <Box
                  paddingX="large"
                  paddingY="large"
                  top={0}
                  paddingRight="medium"
                  width="full"
                  height="full"
                >
                  <Columns space="xlarge">
                    <Column>
                      <Notice tone="info">
                        <Text>
                          {t(
                            "To edit this ad, you'll need to go back to the system you used to post it.",
                          )}
                        </Text>
                      </Notice>
                    </Column>
                    <Column width="content">
                      <ButtonIcon
                        id="dismiss-button-icon"
                        label="Dismiss"
                        icon={<IconClear />}
                        size="large"
                        variant="transparent"
                        onClick={onEditAdWarningModalShouldDismiss}
                      />
                    </Column>
                  </Columns>
                  <Box
                    background="info"
                    paddingLeft="xxsmall"
                    position="absolute"
                    left={0}
                    top={0}
                    bottom={0}
                  />
                </Box>
              </Modal>
            )}
          </Box>
        </Modal>
      ) : null}
    </SecondarySection>
  );
};
