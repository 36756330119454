import { useResponsiveValue } from 'braid-design-system';

export const useDekstopOnlyView = () => {
  const responsiveValue = useResponsiveValue();

  const isDesktopOnly = responsiveValue({
    mobile: false,
    tablet: false,
    desktop: true,
  });

  return isDesktopOnly;
};
