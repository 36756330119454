import { useTranslations } from '@vocab/react';
import { Box, ContentBlock, Heading, Stack } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { FEATURE_APR_NVL, useFeatureToggleData } from '../../featureToggle';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { AdCentreFooter } from '../AdCentreFooter/AdCentreFooter';
import { AprBreadcrumb } from '../AprBreadcrumb/AprBreadcrumb';
import { Header } from '../Header/Header';
import { HeaderExtension } from '../HeaderExtension/HeaderExtension';
import { SystemBanner } from '../SystemBanner/SystemBanner';
import { ZStack } from '../ZStack/ZStack';
import { ZStackItem } from '../ZStackItem/ZStackItem';

import translations from './.vocab';

type ContentBlockProps = ComponentProps<typeof ContentBlock>;

export interface AdPerformancePageFrameProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  children: ReactNodeNoStrings;
  hirerId?: number;
  stickyBar?: JSX.Element;
}

export const AdPerformancePageFrame = ({
  adCentreAccountInfo,
  children,
  hirerId,
  stickyBar,
}: AdPerformancePageFrameProps) => {
  const { t } = useTranslations(translations);
  const isAPRNVLEnabled = useFeatureToggleData(FEATURE_APR_NVL, false);

  const contentBlockProps: ContentBlockProps = isAPRNVLEnabled
    ? { width: 'large', align: 'center', children }
    : { children };

  // @TODO: Deprecate this function
  const renderAprBreadcrumb = () => (
    <Box position="relative">
      <ZStack height="full" width="full">
        <ZStackItem>
          <HeaderExtension />
        </ZStackItem>
        <ZStackItem>
          <Box>
            <AprBreadcrumb />
          </Box>
        </ZStackItem>
      </ZStack>
    </Box>
  );

  return (
    <Box>
      <Header
        adCentreAccountInfo={adCentreAccountInfo}
        hirerId={`${hirerId || ''}`}
        currentPage="ad performance report"
      />
      {stickyBar ?? null}
      <SystemBanner />
      <Stack space="large">
        {/* TODO: Remove this part after NVL completion, the aprBreadCrumb should be in the ContentBlock */}
        {isAPRNVLEnabled ? null : renderAprBreadcrumb()}
        <ContentBlock {...contentBlockProps}>
          {isAPRNVLEnabled ? <AprBreadcrumb /> : null}
          <Box style={{ marginTop: isAPRNVLEnabled ? 0 : 50 }}>
            <Stack space="large">
              <Box
                paddingX={isAPRNVLEnabled ? 'none' : 'large'}
                marginLeft={
                  isAPRNVLEnabled ? 'none' : ['none', 'large', 'large']
                }
              >
                <Heading level={isAPRNVLEnabled ? '2' : '1'}>
                  {t('My ad performance')}
                </Heading>
              </Box>
              {children}
            </Stack>
          </Box>
        </ContentBlock>
        <AdCentreFooter
          adCentreAccountInfo={adCentreAccountInfo}
          currentPage="ad performance report"
        />
      </Stack>
    </Box>
  );
};
