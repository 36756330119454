import { useResponsiveValue } from 'braid-design-system';

export const useMobileAndTabletView = () => {
  const responsiveValue = useResponsiveValue();

  const isMobileAndTablet = responsiveValue({
    mobile: true,
    tablet: true,
    desktop: false,
  });

  return isMobileAndTablet;
};
