
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoi4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LmC4Lir4Lil4LiU4Lir4LiZ4LmJ4Liy4LiZ4Li14LmJ4LmE4LiU4LmJIOC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4nuC4ouC4suC4ouC4suC4oeC5geC4geC5ieC5hOC4guC5guC4lOC4ouC5gOC4o+C5h+C4p+C4l+C4teC5iOC4quC4uOC4lCDguKXguK3guIfguKPguLXguYDguJ/guKPguIrguKvguJnguYnguLLguJnguLXguYnguKvguKPguLfguK3guIHguKXguLHguJrguKHguLLguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIciLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdy4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoi4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LmC4Lir4Lil4LiU4Lir4LiZ4LmJ4Liy4LiZ4Li14LmJ4LmE4LiU4LmJIOC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4nuC4ouC4suC4ouC4suC4oeC5geC4geC5ieC5hOC4guC5guC4lOC4ouC5gOC4o+C5h+C4p+C4l+C4teC5iOC4quC4uOC4lCDguKXguK3guIfguKPguLXguYDguJ/guKPguIrguKvguJnguYnguLLguJnguLXguYnguKvguKPguLfguK3guIHguKXguLHguJrguKHguLLguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIciLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdy4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiU2FhdCBpbmkga2FtaSB0aWRhayBkYXBhdCBtZW11YXRueWEuIE11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdy4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiU2FhdCBpbmkga2FtaSB0aWRhayBkYXBhdCBtZW11YXRueWEuIE11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdy4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoi4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LmC4Lir4Lil4LiU4Lir4LiZ4LmJ4Liy4LiZ4Li14LmJ4LmE4LiU4LmJIOC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4nuC4ouC4suC4ouC4suC4oeC5geC4geC5ieC5hOC4guC5guC4lOC4ouC5gOC4o+C5h+C4p+C4l+C4teC5iOC4quC4uOC4lCDguKXguK3guIfguKPguLXguYDguJ/guKPguIrguKvguJnguYnguLLguJnguLXguYnguKvguKPguLfguK3guIHguKXguLHguJrguKHguLLguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIciLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdy4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoi4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LmC4Lir4Lil4LiU4Lir4LiZ4LmJ4Liy4LiZ4Li14LmJ4LmE4LiU4LmJIOC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4nuC4ouC4suC4ouC4suC4oeC5geC4geC5ieC5hOC4guC5guC4lOC4ouC5gOC4o+C5h+C4p+C4l+C4teC5iOC4quC4uOC4lCDguKXguK3guIfguKPguLXguYDguJ/guKPguIrguKvguJnguYnguLLguJnguLXguYnguKvguKPguLfguK3guIHguKXguLHguJrguKHguLLguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIciLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdy4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiU2FhdCBpbmkga2FtaSB0aWRhayBkYXBhdCBtZW11YXRueWEuIE11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdy4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiU2FhdCBpbmkga2FtaSB0aWRhayBkYXBhdCBtZW11YXRueWEuIE11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdy4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdywgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiW8W04bq94bq94bq9IMOnxIPEg8SD4LiB4Li14LmJJ+G5ryDGmsO2w7bDtsSDxIPEg8aMIOG5r+G4qcOsw6zDrMWhIMWZw6zDrMOsxKPhuKnhua8g4LiB4Li14LmJw7bDtsO2xbUsIMOfx5rHmsea4bmvIMW14bq94bq94bq9J8WZ4bq94bq94bq9IMaMw7bDtsO2w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO2x5rHmseaxZkgw5/hur3hur3hur3FoeG5ryDhua/DtsO2w7YgxpLDrMOsw6zqrZUgw6zDrMOs4bmvLiDFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua/huKnhur3hur3hur0gxqXEg8SDxIPEo+G6veG6veG6vSDDtsO2w7bFmSDDp+G4qeG6veG6veG6vcOnxLcgw5/Eg8SDxIPDp8S3IMaaxIPEg8SD4bmv4bq94bq94bq9xZkuXSIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJbxbThur3hur3hur0gw6fEg8SDxIPguIHguLXguYkn4bmvIMaaw7bDtsO2xIPEg8SDxowg4bmv4bipw6zDrMOsxaEgxZnDrMOsw6zEo+G4qeG5ryDguIHguLXguYnDtsO2w7bFtS4gxZjhur3hur3hur3GksWZ4bq94bq94bq9xaHhuKkg4bmv4bip4bq94bq94bq9IMalxIPEg8SDxKPhur3hur3hur0gw7bDtsO2xZkgw6fhuKnhur3hur3hur3Dp8S3IMOfxIPEg8SDw6fEtyDGmsSDxIPEg+G5r+G6veG6veG6vcWZLl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93LCBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiW8W04bq94bq94bq9IMOnxIPEg8SD4LiB4Li14LmJJ+G5ryDGmsO2w7bDtsSDxIPEg8aMIOG5r+G4qcOsw6zDrMWhIMWZw6zDrMOsxKPhuKnhua8g4LiB4Li14LmJw7bDtsO2xbUsIMOfx5rHmsea4bmvIMW14bq94bq94bq9J8WZ4bq94bq94bq9IMaMw7bDtsO2w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO2x5rHmseaxZkgw5/hur3hur3hur3FoeG5ryDhua/DtsO2w7YgxpLDrMOsw6zqrZUgw6zDrMOs4bmvLiDFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua/huKnhur3hur3hur0gxqXEg8SDxIPEo+G6veG6veG6vSDDtsO2w7bFmSDDp+G4qeG6veG6veG6vcOnxLcgw5/Eg8SDxIPDp8S3IMaaxIPEg8SD4bmv4bq94bq94bq9xZkuXSIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJbxbThur3hur3hur0gw6fEg8SDxIPguIHguLXguYkn4bmvIMaaw7bDtsO2xIPEg8SDxowg4bmv4bipw6zDrMOsxaEgxZnDrMOsw6zEo+G4qeG5ryDguIHguLXguYnDtsO2w7bFtS4gxZjhur3hur3hur3GksWZ4bq94bq94bq9xaHhuKkg4bmv4bip4bq94bq94bq9IMalxIPEg8SDxKPhur3hur3hur0gw7bDtsO2xZkgw6fhuKnhur3hur3hur3Dp8S3IMOfxIPEg8SDw6fEtyDGmsSDxIPEg+G5r+G6veG6veG6vcWZLl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    