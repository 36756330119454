import { Box, ContentBlock, Heading, Stack } from 'braid-design-system';
import type { ReactNode } from 'react';

export interface PageErrorProps {
  icon: JSX.Element;
  title: ReactNode;
  description: ReactNodeNoStrings;
}

export const PageError = ({ icon, title, description }: PageErrorProps) => (
  <Box background="surface" padding="large">
    <ContentBlock width="small">
      <Stack space="medium" align="center">
        {icon}
        <Heading level="2" align="center">
          {title}
        </Heading>
        {description}
      </Stack>
    </ContentBlock>
  </Box>
);
