export const NoAccessIconNvl = () => (
  <svg
    width="129"
    height="128"
    viewBox="0 0 129 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.4612 26.2598V35.8816H75.7139V26.2598H53.7785V35.8816H42.4355V26.2598H20.5V88.0297C20.5 95.6014 26.638 101.739 34.2096 101.739H94.6869C102.259 101.739 108.397 95.6014 108.397 88.0297V26.2598H86.4612Z"
      fill="#EE399E"
    />
    <path
      d="M72.5623 55.2974C71.0939 52.6462 68.2703 50.8496 65.0248 50.8496C61.7793 50.8496 58.9558 52.6462 57.4873 55.2974H72.5623Z"
      fill="#072462"
    />
    <path
      d="M57.8612 64.2227C58.4607 65.121 59.2175 65.9056 60.1026 66.5238L56.4121 82.7347H73.6389L69.5118 66.804C70.58 66.1498 71.482 65.261 72.1774 64.2227H57.8612Z"
      fill="#072462"
    />
    <path
      d="M102.226 45.6055C95.9592 45.6055 90.6347 49.6701 88.7195 55.2972H57.488C56.804 56.5321 56.4121 57.951 56.4121 59.4627C56.4121 61.2263 56.9506 62.8596 57.8612 64.2238H88.6316C90.4744 69.9766 95.8694 74.1548 102.226 74.1548C110.097 74.1548 116.5 67.7515 116.5 59.8801C116.5 52.0088 110.097 45.6055 102.226 45.6055ZM102.226 65.9292C98.89 65.9292 96.1765 63.2157 96.1765 59.8801C96.1765 56.5446 98.89 53.8311 102.226 53.8311C105.561 53.8311 108.275 56.5446 108.275 59.8801C108.275 63.2157 105.561 65.9292 102.226 65.9292Z"
      fill="#FDC221"
    />
    <path
      d="M41.9492 56.0723H29.6508C27.8956 56.0723 26.4727 57.4952 26.4727 59.2504V64.6879C26.4727 66.4431 27.8956 67.8661 29.6508 67.8661H41.9492C43.7045 67.8661 45.1274 66.4431 45.1274 64.6879V59.2504C45.1274 57.4952 43.7045 56.0723 41.9492 56.0723Z"
      fill="#E60278"
    />
    <path
      d="M98.7246 81.4355H86.4262C84.671 81.4355 83.248 82.8585 83.248 84.6137V90.0512C83.248 91.8064 84.671 93.2293 86.4262 93.2293H98.7246C100.48 93.2293 101.903 91.8064 101.903 90.0512V84.6137C101.903 82.8585 100.48 81.4355 98.7246 81.4355Z"
      fill="#E60278"
    />
  </svg>
);
