import { type Country, createAbsoluteUrl } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Badge,
  Box,
  ButtonLink,
  Column,
  Columns,
  Divider,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import { useCallback, type ReactNode } from 'react';

import { useConfig } from '../../App/ConfigContext';
import type { SimilarAdsDetails } from '../../gql/generated';
import { useDekstopOnlyView } from '../../hooks/useDesktopOnlyView';
import { useMobileAndTabletView } from '../../hooks/useMobileAndTabletView';
import { useMobileView } from '../../hooks/useMobileView';
import { useTabletOnlyView } from '../../hooks/useTabletOnlyView';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { ElapsedTime } from '../ElapsedTime/ElapsedTime';
import { GenericTitleContainer } from '../GenericTitleContainer/GenericTitleContainer';

import localTranslations from './.vocab';

import * as styles from './SimilarAds.css';

export interface SimilarAdsProps {
  title: ReactNode;
  sortedAds: SimilarAdsDetails[];
}

const productDisplayNameMap: Record<
  string,
  'BRANDED' | 'PREMIUM' | 'STANDOUT'
> = {
  BRANDED: 'BRANDED',
  PREMIUM: 'PREMIUM',
  STANDOUT: 'STANDOUT',
};

export const SimilarAdsNvl = ({ title, sortedAds }: SimilarAdsProps) => {
  const { t } = useTranslations(localTranslations);
  const { country, language } = useConfig();

  const isDesktop = useDekstopOnlyView();

  const isMobile = useMobileView();

  const isMobileAndTablet = useMobileAndTabletView();

  const isTabletOnly = useTabletOnlyView();

  const adBadgesFromAd = (ad: SimilarAdsDetails): string[] =>
    [
      productDisplayNameMap[ad.productDisplayName]
        ? [t(`Badge ${productDisplayNameMap[ad.productDisplayName]}`)]
        : [],
      ad.hasKeySellingPoints ? [t('Badge key selling points')] : [],
      !ad.isSalaryHidden ? [t('Badge salary displayed')] : [],
    ].flat();

  const similarJobUrl = createAbsoluteUrl({
    product: 'candidate',
    country: country as Country,
    language,
    path: `/expiredjob/`,
  });

  const onSimilarAdClicked = useCallback(() => {
    trackEvent('similar_ad_pressed', {
      objectInteraction: 'apr-similar-ad',
    });
  }, []);

  return (
    <GenericTitleContainer title={title} weight="weak">
      <Box>
        <Stack space="medium">
          <Divider />
          {sortedAds.map((ad, index) => {
            const advertiserName = ad.isPrivateAdvertiser
              ? t('Private Advertiser')
              : ad.advertiserName;

            return (
              <Box key={index} data={{ id: 'ui-similar-ads-section-ad' }}>
                <Box paddingBottom="medium">
                  <Stack space="medium">
                    <Columns
                      space={isMobileAndTablet ? 'medium' : 'xxlarge'}
                      alignY="center"
                      collapseBelow="desktop"
                    >
                      <Column width="1/2">
                        <Columns
                          space="large"
                          alignY="center"
                          collapseBelow="desktop"
                        >
                          <Column width="1/5">
                            <Box>
                              {!ad.isPrivateAdvertiser && ad.serpLogoUrl ? (
                                <img
                                  className={styles.logo}
                                  src={ad.serpLogoUrl}
                                  alt={advertiserName}
                                  title={advertiserName}
                                />
                              ) : (
                                <Box title={advertiserName}>
                                  <Text
                                    size="small"
                                    maxLines={1}
                                    weight="medium"
                                    tone="secondary"
                                  >
                                    {advertiserName}
                                  </Text>
                                </Box>
                              )}
                            </Box>
                          </Column>
                          <Column width="4/5">
                            <Columns space="none">
                              <Column width={isTabletOnly ? '4/5' : undefined}>
                                <Stack space="small">
                                  <Text maxLines={1} weight="strong">
                                    {ad.jobTitle}
                                  </Text>
                                  <Box title={ad.jobLocationLabel || ''}>
                                    <Text maxLines={1}>
                                      {ad.jobLocationLabel}
                                    </Text>
                                  </Box>
                                  <Text>
                                    <ElapsedTime
                                      pastTime={ad.jobCreatedTimestampUTC}
                                      showTitle={true}
                                      id="ui-similar-ads-section-created-date"
                                    >
                                      {({ renderProps }) =>
                                        t(`Posted ${renderProps.type}`, {
                                          value: renderProps.value,
                                        })
                                      }
                                    </ElapsedTime>
                                  </Text>
                                </Stack>
                              </Column>
                              {isTabletOnly ? (
                                <Column width="1/5">
                                  <Box>
                                    <ButtonLink
                                      data={{
                                        id: `ui-similar-ads-row-${index}-link`,
                                      }}
                                      href={`${similarJobUrl}${ad.jobId}`}
                                      target="_blank"
                                      onClick={onSimilarAdClicked}
                                    >
                                      {t('View ad')}
                                    </ButtonLink>
                                  </Box>
                                </Column>
                              ) : null}
                            </Columns>
                          </Column>
                        </Columns>
                      </Column>
                      <Column width="1/2">
                        <Columns
                          space={isMobileAndTablet ? 'medium' : 'none'}
                          alignY="center"
                          align={isDesktop ? 'right' : undefined}
                          collapseBelow="tablet"
                        >
                          <Column width={isDesktop ? '3/4' : '4/5'}>
                            <Inline space="xxsmall">
                              {adBadgesFromAd(ad).map((adBadge, i) => (
                                <Box
                                  title={adBadge}
                                  key={`ui-similar-ads-badge-${i}`}
                                >
                                  <Badge tone="neutral">{adBadge}</Badge>
                                </Box>
                              ))}
                            </Inline>
                          </Column>
                          {!isTabletOnly ? (
                            <Column width={isDesktop ? 'content' : '1/5'}>
                              <Box
                                style={{ width: isMobile ? '35%' : undefined }}
                              >
                                <ButtonLink
                                  data={{
                                    id: `ui-similar-ads-row-${index}-link`,
                                  }}
                                  href={`${similarJobUrl}${ad.jobId}`}
                                  target="_blank"
                                  onClick={onSimilarAdClicked}
                                >
                                  {t('View ad')}
                                </ButtonLink>
                              </Box>
                            </Column>
                          ) : null}
                        </Columns>
                      </Column>
                    </Columns>
                  </Stack>
                </Box>
                {index < sortedAds?.length ? <Divider /> : null}
              </Box>
            );
          })}
        </Stack>
      </Box>
    </GenericTitleContainer>
  );
};
