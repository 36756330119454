import { useTranslations } from '@vocab/react';
import { Stack, Heading, Columns, Column, Box } from 'braid-design-system';
import { useState } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { PageSectionTracker } from '../../components/PageSectionTracker/PageSectionTracker';
import { PrimarySection } from '../../components/PrimarySection/PrimarySection';
import { useHasAdRating } from '../../context/hasAdRating';
import { useHidden } from '../../hooks/useHidden';
import { ApplicationPerformance } from '../../widgets/ApplicationPerformance/ApplicationPerformance';
import { ApplicationPerformanceFallback } from '../../widgets/ApplicationPerformance/ApplicationPerformanceFallback';
import { ClicksOnYourAd } from '../../widgets/ClicksOnYourAd/ClicksOnYourAd';
import { ClicksOnYourAdFallback } from '../../widgets/ClicksOnYourAd/ClicksOnYourAdFallback';
import { PerformancePrediction } from '../../widgets/PerformancePrediction/PerformancePrediction';
import { PerformancePredictionFallback } from '../../widgets/PerformancePrediction/PerformancePredictionFallback';
import { ViewsInJobSearch } from '../../widgets/ViewsInJobSearch/ViewsInJobSearch';
import { ViewsInJobSearchFallback } from '../../widgets/ViewsInJobSearch/ViewsInJobSearchFallback';

import translations from './.vocab';

export const MoreStats = () => {
  const { t } = useTranslations(translations);
  const isTablet = useHidden([true, true, false]);
  const { hasAdRating, updateHasAdRating } = useHasAdRating();
  const [hasPerformancePredictionError, setHasPerformancePredictionError] =
    useState(false);
  const [hasApplicationPerformanceError, setHasApplicationPerformanceError] =
    useState(false);

  const columnWidth = hasAdRating ? '1/2' : '1/3';

  const handlePerformancePredictionError = () => {
    setHasPerformancePredictionError(true);
  };

  const handleApplicationPerformanceError = () => {
    setHasApplicationPerformanceError(true);
  };

  return (
    <PrimarySection data-id="ui-stats-section">
      <PageSectionTracker
        eventName="ad_performance_page_section_displayed"
        props={{
          currentPageSection: 'see more stats',
          objectInteraction: 'apr-section-see-more-stats',
        }}
      />
      <Stack space="medium">
        {hasAdRating && (
          <Heading level="3" weight="weak">
            {t('More stats')}
          </Heading>
        )}
        <Stack space="medium">
          <Columns space={['small', 'small', 'gutter']} collapseBelow="desktop">
            <Column data={{ id: 'first-column' }} width={columnWidth}>
              <ErrorBoundary fallback={<ViewsInJobSearchFallback />}>
                <ViewsInJobSearch updateHasAdRating={updateHasAdRating} />
              </ErrorBoundary>
            </Column>
            <Column data={{ id: 'second-column' }} width={columnWidth}>
              <ErrorBoundary fallback={<ClicksOnYourAdFallback />}>
                <ClicksOnYourAd updateHasAdRating={updateHasAdRating} />
              </ErrorBoundary>
            </Column>
            {!hasAdRating ? (
              <Column data={{ id: 'third-column' }} width="1/3">
                <ErrorBoundary
                  fallback={<ApplicationPerformanceFallback hasBorder />}
                >
                  <ApplicationPerformance
                    hasBorder
                    updateHasAdRating={updateHasAdRating}
                  />
                </ErrorBoundary>
              </Column>
            ) : null}
          </Columns>
          {hasAdRating && (
            <Box
              data={{ id: 'ui-application-performance-section' }}
              borderRadius="standard"
              height="full"
              style={{
                border: '1px solid #d8dce2',
              }}
            >
              {hasPerformancePredictionError &&
              hasApplicationPerformanceError ? (
                <ApplicationPerformanceFallback />
              ) : (
                <Box display="flex" flexDirection={isTablet ? 'column' : 'row'}>
                  <Box
                    data={{ id: 'ui-application-performance' }}
                    flexShrink={0}
                    flexGrow={1}
                    minWidth={0}
                    style={{
                      maxWidth: !isTablet ? '236px' : 'none',
                    }}
                    width="full"
                  >
                    <ErrorBoundary
                      onError={handleApplicationPerformanceError}
                      fallback={<ApplicationPerformanceFallback />}
                    >
                      <ApplicationPerformance
                        isStandalone
                        updateHasAdRating={updateHasAdRating}
                        onQueryError={handleApplicationPerformanceError}
                      />
                    </ErrorBoundary>
                  </Box>
                  <Box width="full" zIndex={0}>
                    <ErrorBoundary
                      onError={handlePerformancePredictionError}
                      fallback={<PerformancePredictionFallback />}
                    >
                      <PerformancePrediction
                        onQueryError={handlePerformancePredictionError}
                      />
                    </ErrorBoundary>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </PrimarySection>
  );
};
