import { useTranslations } from '@vocab/react';
import { Box, Heading, PageBlock, Stack } from 'braid-design-system';

import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';
import { AdCentreFooter } from '../AdCentreFooter/AdCentreFooter';
import { AprBreadcrumb } from '../AprBreadcrumb/AprBreadcrumb';
import { Header } from '../Header/Header';
import { SystemBanner } from '../SystemBanner/SystemBanner';

import translations from './.vocab';

export interface AdPerformancePageFrameProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  children: ReactNodeNoStrings;
  hirerId?: number;
  stickyBar?: JSX.Element;
}

export const AdPerformancePageFrameNvl = ({
  adCentreAccountInfo,
  children,
  hirerId,
  stickyBar,
}: AdPerformancePageFrameProps) => {
  const { t } = useTranslations(translations);

  return (
    <Box>
      <Header
        adCentreAccountInfo={adCentreAccountInfo}
        hirerId={`${hirerId || ''}`}
        currentPage="ad performance report"
      />
      {stickyBar ?? null}
      <SystemBanner />
      <PageBlock width="large">
        <AprBreadcrumb />
        <Stack space="large">
          <Heading level="1">{t('My ad performance')}</Heading>
          {children}
        </Stack>
      </PageBlock>
      <AdCentreFooter
        adCentreAccountInfo={adCentreAccountInfo}
        currentPage="ad performance report"
      />
    </Box>
  );
};
