import { useTranslations } from '@vocab/react';
import { Text } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AdStatCardNvl } from '../../components/AdStatCard/AdStatCardNvl';
import { AdStatErrorOrLoading } from '../../components/AdStatErrorOrLoading/AdStatErrorOrLoading';
import { useFeatureToggleData, FEATURE_BENCHMARKS } from '../../featureToggle';
import { useApplicationPerformance } from '../../hooks/useApplicationPerformance';

import translations from './.vocab';

export interface ApplicationPerformanceProps {
  updateHasAdRating?: (hasAdRating: boolean) => void;
  onQueryError?: () => void;
}

export const ApplicationPerformanceNvl = ({
  updateHasAdRating,
  onQueryError,
}: ApplicationPerformanceProps) => {
  const { t } = useTranslations(translations);
  const { jobId: jobIdParam } = useParams<{
    jobId: string;
  }>();

  const { isLoading, data, error } = useApplicationPerformance({
    jobId: jobIdParam!,
  });

  const isBenchmarksFeatureEnabled = useFeatureToggleData(
    FEATURE_BENCHMARKS,
    false,
  );

  useEffect(() => {
    if (data?.hasAdRating) {
      updateHasAdRating?.(true);
    }
  }, [updateHasAdRating, data]);

  const hasQueryError = error || !data;

  useEffect(() => {
    if (!isLoading && hasQueryError && onQueryError) {
      onQueryError();
    }
  }, [isLoading, hasQueryError, onQueryError]);

  if ((isLoading && !data) || hasQueryError) {
    return (
      <AdStatErrorOrLoading
        headingLabel={t('Candidates')}
        headingTooltip={t(
          'A candidate completed their application for your job ad on SEEK',
        )}
        isLoading={isLoading}
      />
    );
  }

  const {
    adRatingConfidence,
    applications,
    mobileViewsInPercentage,
    similarAdsAverage,
    hasAdRating,
    isLinkOutAd,
  } = data;
  return (
    <AdStatCardNvl
      adRatingConfidence={adRatingConfidence}
      title={isLinkOutAd ? t('Application starts') : t('Candidates')}
      statCount={applications}
      mobileInPercentage={mobileViewsInPercentage}
      comparisonToSimilarAds={
        similarAdsAverage !== null && similarAdsAverage > 0
          ? (applications - similarAdsAverage) / similarAdsAverage
          : null
      }
      similarAdsAverage={similarAdsAverage}
      displayMarketPerformance={hasAdRating && isBenchmarksFeatureEnabled}
      tooltip={{
        id: 'views-in-job-search-tooltip',
        title: isLinkOutAd ? t('Application starts') : t('Candidates'),
        contents: (
          <Text size="standard">
            {isLinkOutAd
              ? t('A candidate clicked on apply for your job ad on SEEK')
              : t(
                  'A candidate completed their application for your job ad on SEEK',
                )}
          </Text>
        ),
      }}
    />
  );
};
